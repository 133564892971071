import { useSelector, RootStateOrAny } from 'react-redux';
import NavGroup from './component/navGroup';
import menus from './menu';

function MenuList() {
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const navItems = menus.map((item: any) => {
    switch (item.type) {
      case 'group':
        if (item.roles.includes(user.roleId)) {
          return <NavGroup key={item.id} item={item} />;
        }
        return null;
      default:
        return null;
    }
  });
  return <div>{navItems}</div>;
}

export default MenuList;

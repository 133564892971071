import { types } from '../actions/license';

interface LicenseRequest {
  type: string;
  payload?: any;
}

type LicenseActions = LicenseRequest;

interface ILicenseState {
  license: any;
  error: string | null;
  licenses: any;
  total: number;
  newKey: string;
  licensesByUser: any;
  licensesTrialByUser: any;
  count: number;
  licenseTrialValid: number;
  licenseTrialExpried: number;
  licenseRegularValid: number;
  licenseRegularExpried: number;
  licenseCount: number;
  licenseStopCount: number;
  renewalDate: any;
  endDateTrail: any;
}

const initialState: ILicenseState = {
  license: [],
  error: null,
  licenses: [],
  licensesTrialByUser: [],
  total: 0,
  newKey: '',
  licensesByUser: [],
  count: 0,
  licenseTrialValid: 0,
  licenseTrialExpried: 0,
  licenseRegularValid: 0,
  licenseRegularExpried: 0,
  licenseCount: 0,
  licenseStopCount: 0,
  renewalDate: null,
  endDateTrail: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: LicenseActions) => {
  switch (type) {
    case types.CREATE_LICENSE_SUCCESS:
      state.license = payload;
      return {
        ...state,
      };
    case types.SEARCH_LICENSES_SUCCESS:
      state.licenses = payload.list;
      state.total = payload.count;
      return {
        ...state,
      };
    case types.MAIL_LICENSES_END_DATE_TRIAL_10_DAY_SUCCESS:
      state.licenses = payload.list;
      state.total = payload.count;
      return {
        ...state,
      };
    case types.MAIL_LICENSES_PAYMENT_SUCCESS:
      state.licenses = payload.list;
      state.total = payload.count;
      return {
        ...state,
      };
    case types.MAIL_LICENSES_ENDDATE_60_DAY_SUCCESS:
      state.licenses = payload.list;
      state.total = payload.count;
      return {
        ...state,
      };
    case types.SEND_MAIL_LICENSES_END_DATE_TRIAL_10_DAY_SUCCESS:
      state.licenses = payload.list;
      return {
        ...state,
      };
    case types.SEND_MAIL_REGISTER_LICENSE_SUCCESS:
      state.licenses = payload.list;
      return {
        ...state,
      };
    case types.SEND_MAIL_LICENSE_PAYMENT_SUCCESS:
      state.licenses = payload.list;
      return {
        ...state,
      };
    case types.SEND_MAIL_LICENSES_ENDDATE_60_DAY:
      state.licenses = payload.list;
      return {
        ...state,
      };
    case types.SEND_MAIL_REGISTER_LICENSE:
      state.licenses = payload.list;
      return {
        ...state,
      };
    case types.LICENSE_DETAIL_SUCCESS:
      state.license = payload;
      return {
        ...state,
      };
    case types.EDIT_LICENSE_SUCCESS: {
      const index = state.licenses.findIndex((item: any) => item.licenseId === payload.licenseId);
      if (index >= 0) {
        state.licenses[index] = payload;
        state.license = payload;
      }
      return {
        ...state,
        pending: true,
      };
    }
    case types.LICENSE_IMPORT_USER_SUCCESS:
      return {
        ...state,
      };
    case types.LICENSE_NEW_KEY_SUCCESS:
      state.newKey = payload;
      return {
        ...state,
      };
    case types.GET_LICENSES_BY_USER_SUCCESS:
      state.licensesByUser = payload.list;
      state.count = payload.count;
      return {
        ...state,
      };
    case types.GET_LIST_LICENSES_STOP_SUCCESS:
      state.licensesByUser = payload.list;
      state.count = payload.count;
      return {
        ...state,
      };
    case types.GET_LICENSES_BY_TEMPORARYID_SUCCESS2:
      state.licensesByUser = payload.list;
      state.count = payload.count;
      return {
        ...state,
      };
    case types.GET_LICENSES_TRIAL_BY_USER_SUCCESS:
      state.licensesTrialByUser = payload;
      return {
        ...state,
      };
    case types.EDIT_LICENSES_STATUS_SUCCESS:
      if (payload.list != null && payload.list.length > 0) {
        state.licensesByUser = payload.list;
        state.count = payload.count;
      }
      return {
        ...state,
      };
    case types.GET_STATUS_LICENSES_BY_USER_LOGIN_SUCCESS:
      state.licenseTrialValid = payload.licenseTrialValidCount;
      state.licenseTrialExpried = payload.licenseTrialExpriedCount;
      state.licenseRegularValid = payload.licenseRegularValidCount;
      state.licenseRegularExpried = payload.licenseRegularExpriedCount;
      state.licenseCount = payload.licenseCount;
      state.licenseStopCount = payload.licenseStopCount;
      return {
        ...state,
      };
    case types.GET_RENEWAL_DATE_SUCCESS:
      state.renewalDate = payload.endDate;
      state.endDateTrail = payload.endDateTrail;
      return {
        ...state,
      };
    case types.GET_LICENSES_BANK_UPDATE_SUCCESS:
      state.licensesByUser = payload.list;
      state.count = payload.count;
      return {
        ...state,
      };
    case types.UPDATE_LICENSES_BANK_SUCCESS:
      state.license = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

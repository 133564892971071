// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class GroupPasswordPolicyServices {
  static createGroupPasswordPolicy(params: any) {
    const url = 'policy/password/create';
    return axiosClient.post(url, params);
  }

  static editGroupPasswordPolicy(params: any) {
    const url = 'policy/password/edit';
    return axiosClient.put(url, params);
  }

  static groupPasswordPolicyDetail(params: any) {
    const url = 'policy/password/detail';
    return axiosClient.get(url, { params });
  }
}
export default GroupPasswordPolicyServices;

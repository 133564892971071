import { useState, useEffect } from 'react';
import { AppBar, Box, CssBaseline, styled, Theme, Toolbar, useTheme, useMediaQuery } from '@mui/material';
import { useSelector, RootStateOrAny } from 'react-redux';
import Header from './header';
import Sidebar from './sidebar';
import customTheme from '../../../themes/custom';
import Loading from '../../../component/loading';
import { DRAWER_WIDTH } from '../../../constants/drawer';

interface MainProps {
  theme: Theme;
  open: any;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<MainProps>(({ theme, open }: MainProps) => ({
  ...customTheme().mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(DRAWER_WIDTH - 20),
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  })
}));

function WrapMain({ children }: any) {
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(true);
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  const loading = useSelector((state: RootStateOrAny) => state.app.loading);

  useEffect(() => {
    if (matchDownMd) {
      setDrawerOpen(false);
    }
  }, [matchDownMd]);

  const handleLeftDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Loading loading={loading} type="full" />
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={() => setDrawerOpen(!drawerOpen)} />
        </Toolbar>
      </AppBar>
      <Sidebar drawerOpen={drawerOpen} drawerToggle={handleLeftDrawerToggle} />
      <Main theme={theme} open={drawerOpen}>
        {children}
      </Main>
    </Box>
  );
}

export default WrapMain;

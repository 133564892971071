import { Button } from '@mui/material';
import { t } from 'i18next';
import PublishIcon from '@mui/icons-material/Publish';

type Props = {
  handleClick: () => void;
};

export default function DownloadButton({ handleClick }: Props) {
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      sx={{ ml: 1 }}
      startIcon={<PublishIcon />}
    >
      {t('common.btn.import')}
    </Button>
  );
}

import { Button } from '@mui/material';
import { t } from 'i18next';
import DownloadIcon from '@mui/icons-material/Download';

type Props = {
  handleClick: () => void;
};

export default function DownloadButton({ handleClick }: Props) {
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      sx={{ ml: 1 }}
      startIcon={<DownloadIcon />}
    >
      {t('common.btn.download')}
    </Button>
  );
}

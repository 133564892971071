export const types = {
  CHANGE_LOADING: 'CHANGE_LOADING',
  CHANGE_ACTIVE_MENU: 'CHANGE_ACTIVE_MENU',
  CHANGE_EDITABLE: 'CHANGE_EDITABLE',
};

export const changeLoading = (payload: any) => ({
  type: types.CHANGE_LOADING,
  payload
});

export const changeActiveMenu = (payload: any) => ({
  type: types.CHANGE_ACTIVE_MENU,
  payload
});

export const changeEditable = (payload: boolean) => ({
  type: types.CHANGE_EDITABLE,
  payload
});

/* eslint-disable max-len */
import { Typography, Divider, List, ListItem, ListItemText, Button, Box, Alert } from '@mui/material';
import { useState, useEffect, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import routes from '../../../constants/routes';
import TGTextField from '../../../component/Elements/TGTextField';
import TGGrid from '../../../component/Elements/TGGrid';
import { RegisterInformation } from './types';
import { start } from '../../../lib/saga-promise';
import ICountryDdl from '../../../modal/ICountryDdl';
import CountrySelector from '../../../component/Form/CountrySelector';
import { getCountries } from '../../../state/actions/country';
import DialogConfirmCancel from '../confirmCancel';

type Props = {
  licensePurchaser: string;
  mailRegister: string;
  formData: any;
  setFormData: React.Dispatch<SetStateAction<any>>;
};

// common css
const labelStyle = {
  fontSize: '0.9rem',
  background: 'rgb(241, 241, 241)',
  height: '40px',
  alignItems: 'center',
  display: 'flex',
  padding: '0 10px',
};

/**
 * Calculate licesne expired data
 * @param date 利用開始希望日
 * @returns 有効期限①, 有効期限②
 */
const performCalculation = (date: string) => {
  if (date) {
    const selectedDateTime = new Date(date);

    selectedDateTime.setDate(selectedDateTime.getDate() + 29);
    const formattedEndDate1 = `${selectedDateTime.getFullYear()}
      /${(selectedDateTime.getMonth() + 1).toString().padStart(2, '0')}
      /${selectedDateTime.getDate().toString().padStart(2, '0')}`;

    selectedDateTime.setDate(selectedDateTime.getDate());
    selectedDateTime.setFullYear(selectedDateTime.getFullYear() + 1);
    const formattedEndDate2 = `${selectedDateTime.getFullYear()}
      /${(selectedDateTime.getMonth() + 1).toString().padStart(2, '0')}
      /${selectedDateTime.getDate().toString().padStart(2, '0')}`;

    return [formattedEndDate2, formattedEndDate1];
  }
  return [];
};

// use for backend as required model
const totalAmount = '0';
// use to detect current form type
const purchaseType = 'Trial';

const currentDate = new Date();
const formatCurrentDate = currentDate.toISOString().slice(0, 10);

const endDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());

// set current date in validate
const currentDateCustomHour = new Date();
currentDateCustomHour.setHours(0, 0, 0, 0);

export default function InformationUserTrail({ licensePurchaser, mailRegister, formData, setFormData }: Props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [codeError, setCodeError] = useState<string>('');

  // ================================================ LOCAL STATE AREA ========================================= //
  const [open, setOpen] = useState(false);
  const [, setSelectedStartDate] = useState(currentDate.toISOString().slice(0, 10));
  const [selectedEndDate, setSelectedEndDate] = useState(
    `${endDate.getFullYear()}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate
      .getDate()
      .toString()
      .padStart(2, '0')}`
  );
  const [selectedEndDate2, setSelectedEndDate2] = useState(
    `${endDate.getFullYear()}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate
      .getDate()
      .toString()
      .padStart(2, '0')}`
  );
  const countries: ICountryDdl[] = useSelector((state: RootStateOrAny) => state.country.allCountries);
  let country = countries.map(({ countryId, countryNameJp }) => ({ countryId, countryNameJp }));
  if (country.length < 1) {
    country = [
      {
        countryId: 'JPN',
        countryNameJp: '日本',
      },
    ];
  }

  useEffect(() => {
    start(getCountries, { noLoading: true }, dispatch);
  }, [dispatch]);
  // ================================================ END LOCAL STATE AREA ========================================= //

  // validate form input
  const schemaInputUser = yup.object({
    countryId: yup.string().required(t('groupCreate.msg.requiredCountryId')),
    businessOwnerName: yup
      .string()
      .trim()
      .required(t('purchase.msg.requiredBusinessOwnerName'))
      .max(256, t('purchase.msg.requiredBusinessOwnerName')),
    businessOwnerNameFurigana: currentLanguage === 'ja' ?
      yup
        .string()
        .trim()
        .matches(/^[\u30A0-\u30FF\s]+$/, t('purchase.msg.matchedKatakana'))
        .required(t('purchase.msg.requiredBusinessOwnerNameFurigana'))
        .max(256, t('purchase.msg.requiredBusinessOwnerNameFurigana'))
      : yup.string().trim(),
    zipCode: currentLanguage === 'ja' ?
      yup.string()
        .trim()
        .required(t('purchase.msg.requiredZipCode'))
        .length(7, t('purchase.msg.requiredZipCode'))
        .matches(/^[0-9]+$/, t('purchase.msg.requiredZipCode'))
      : yup.string()
        .trim()
        .required(t('purchase.msg.requiredZipCode'))
      // .length(7, t('purchase.msg.requiredZipCode'))
        .matches(/^[0-9]+$/, t('purchase.msg.requiredZipCode')),
    address: yup
      .string()
      .trim()
      .required(t('purchase.msg.requiredAddress'))
      .max(256, t('purchase.msg.requiredAddress')),
    telephoneNumber: yup
      .string()
      .trim()
      .required(t('purchase.msg.requiredTelephoneNumber'))
      .matches(/^[0-9]+$/, t('purchase.msg.requiredTelephoneNumberHalfWidth'))
      .max(11, t('purchase.msg.requiredTelephoneNumber'))
      .min(10, t('purchase.msg.requiredTelephoneNumber')),
    profession: yup
      .string()
      .trim()
      // .required(t('purchase.msg.requiredProfession'))
      .max(256, t('purchase.msg.requiredProfession')),
    workplace: yup
      .string()
      .trim()
      // .required(t('purchase.msg.requiredWorkplace'))
      .max(256, t('purchase.msg.requiredWorkplace')),
    startDate: yup
      .date()
      .required(t('licenseCreate.msg.requiredStartDate'))
      .min(currentDateCustomHour, t('purchase.msg.invalidStartDate'))
      .max('2100/12/31', t('purchase.msg.invalidStartDate')),
  });

  // form stuffs
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<RegisterInformation>({
    resolver: yupResolver(schemaInputUser),
    defaultValues: {
      countryId: formData.countryId !== '' ? formData.countryId : 'JPN',
    },
  });

  // end session after 1 hour no operations are performed on the site
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | undefined>();
  const timeout = 3600000; // 1 hour

  const resetTimeout = () => {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    const newTimeout = setTimeout(() => {
      navigate(routes.purchase);
    }, timeout);
    setTimeoutRef(newTimeout);
  };

  // save data input when change form
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
    resetTimeout();
  };

  // save selected country when change form
  const watchCountry = watch('countryId');
  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      countryId: watchCountry,
    }));
    resetTimeout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCountry, setFormData]);

  // mouse event in dialog
  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleConfirmation = (confirm: boolean) => {
    if (confirm) {
      navigate(routes.purchase);
    }
  };

  // recaculate expire date when change start date
  const watchedStartDate = watch('startDate');
  const defaultDate = new Date().toISOString().split('T')[0];
  useEffect(() => {
    if (watchedStartDate < defaultDate) {
      setSelectedStartDate(defaultDate);
      const [selectedDateTime, selectedDateTime1] = performCalculation(defaultDate);
      setSelectedEndDate(selectedDateTime);
      setSelectedEndDate2(selectedDateTime1);
    } else {
      setSelectedStartDate(watchedStartDate);
      const [selectedDateTime, selectedDateTime1] = performCalculation(watchedStartDate);
      setSelectedEndDate(selectedDateTime);
      setSelectedEndDate2(selectedDateTime1);
    }
  }, [watchedStartDate, defaultDate]);

  // submit form
  const onSubmit: SubmitHandler<RegisterInformation> = async (data) => {
    try {
      const startDateObj = new Date(data.startDate);
      startDateObj.setHours(23);
      startDateObj.setMinutes(59);
      data.amountOfLicense = 1;
      const dataInput = {
        ...data,
        mailRegister,
        licensePurchaser,
        totalAmount,
        purchaseType,
        startDate: startDateObj,
      };
      localStorage.setItem('dataInput', JSON.stringify(dataInput));
      localStorage.setItem('purchaseType', purchaseType);
      localStorage.setItem('infoForm', 'UserForm');
      if (param.id) {
        localStorage.setItem('oneTimeKeyId', param.id);
        window.location.href = '/purchase/ConfirmInfo';
      }
    } catch (error: any) {
      let err = error.data.errorCode ? error.data.errorCode : error.data.replace(/[{}]/g, '').trim();
      if (err.split('=').length > 1) {
        err = err.split('=')[1].trim();
      }
      if (err === 'purchaseEmail.emailRegistered') {
        setCodeError(t('purchaseEmail.emailRegistered'));
      } else {
        setCodeError(t('purchaseEmail.systemError'));
      }
      window.scrollTo(0, 0);
    }
  };

  // event change language, turn off errors message
  const [showErrors, setShowErrors] = useState(true);
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);

  // show errors when click button submit
  const handleFormSubmit = () => {
    setShowErrors(true);
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <TGGrid item sm={12} mt={3}>
        {showErrors && codeError && (
          <Alert severity="error" icon={false} sx={{ marginTop: '1rem' }}>
            {codeError}
          </Alert>
        )}
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1}>
        {t('TrailUserInformation')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={3}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseCountryName')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <CountrySelector
            countries={countries}
            countryId={watch('countryId')}
            handleChange={(data) => setValue('countryId', data?.countryId ?? '')}
            error={showErrors ? 'countryId' in errors : false}
            message={showErrors ? errors.countryId?.message : ''}
            notLabel
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseBusinessOwnerName')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            registration={register('businessOwnerName')}
            error={showErrors ? 'businessOwnerName' in errors : false}
            helperText={showErrors ? errors.businessOwnerName?.message : ''}
            label=""
            variant="outlined"
            value={formData.businessOwnerName}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="h6">{t('purchaseBusinessOwnerNameDescription')}</Typography>
        </TGGrid>
      </TGGrid>
      {currentLanguage === 'ja' && (
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseBusinessOwnerNameFurigana')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            registration={register('businessOwnerNameFurigana')}
            error={showErrors ? 'businessOwnerNameFurigana' in errors : false}
            helperText={showErrors ? errors.businessOwnerNameFurigana?.message : ''}
            label=""
            variant="outlined"
            value={formData.businessOwnerNameFurigana}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      )}
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseEmailAddress')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography
            variant="body1"
            id="email-address"
            component="a"
            href={`mailto:${mailRegister}`}
            className="fs-09-rem"
          >
            {mailRegister}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseZipcode')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid mr={1}>〒</TGGrid>
        <TGGrid container item xs={9}>
          <TGGrid item md={2} xs={5}>
            <TGTextField
              fullWidth
              registration={register('zipCode')}
              error={showErrors ? 'zipCode' in errors : false}
              // helperText={errors.zipCode?.message}
              label=""
              variant="outlined"
              value={formData.zipCode}
              onInput={handleInputChange}
            />
          </TGGrid>
          <TGGrid item md={5} xs={5} mt={1} ml={1}>
            <Typography variant="h6">
              {t('purchaseZipCodeDes')}
            </Typography>
          </TGGrid>
          {errors.zipCode && (
            <TGGrid item xs={12} className="text-error">
              {showErrors ? errors.zipCode.message : ''}
            </TGGrid>
          )}
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1} mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseAddress')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            registration={register('address')}
            error={showErrors ? 'address' in errors : false}
            helperText={showErrors ? errors.address?.message : ''}
            label=""
            variant="outlined"
            value={formData.address}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseTelephoneNumber')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            inputProps={{
              maxLength: 11,
            }}
            registration={register('telephoneNumber')}
            error={showErrors ? 'telephoneNumber' in errors : false}
            helperText={showErrors ? errors.telephoneNumber?.message : ''}
            label=""
            variant="outlined"
            value={formData.telephoneNumber}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8} mt={1}>
          <Typography variant="h6">{t('purchaseTelephoneDescription')}</Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseProfession')}
            {/* <span className="required-asterisk">*</span> */}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            registration={register('profession')}
            error={showErrors ? 'profession' in errors : false}
            helperText={showErrors ? errors.profession?.message : ''}
            label=""
            variant="outlined"
            value={formData.profession}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseWorkplace')}
            {/* <span className="required-asterisk">*</span> */}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            registration={register('workplace')}
            error={showErrors ? 'workplace' in errors : false}
            helperText={showErrors ? errors.workplace?.message : ''}
            label=""
            variant="outlined"
            value={formData.workplace}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1}>
        {t('purchaseRegisterInformation')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid my={1}>
        <List>
          <ListItem sx={{ padding: '0' }}>
            <ListItemText primary={t('purchaseRegisterInformationDescription1')} />
          </ListItem>
          <ListItem sx={{ padding: '0' }}>
            <ListItemText primary={t('purchaseRegisterInformationDescription2')} />
          </ListItem>
        </List>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('common.label.username')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" style={labelStyle}>
            {t('purchaseNotificationFieldRegisterTrial')}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('common.label.email')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" style={labelStyle}>
            {t('purchaseNotificationFieldRegister2Trial')}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('common.label.nickname')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" style={labelStyle}>
            {t('purchaseNotificationFieldRegister3Trial')}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="h6">{t('purchaseNickNameDescription')}</Typography>
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1}>
        {t('TrailDetails')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={3}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('TrailAmountOfLicense')}
          </Typography>
        </TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {1}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
        <TGGrid item md={2} xs={3}>
          {}
        </TGGrid>
        <TGGrid item md={10} xs={9}>
          <List>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailUsersDetailsDescription1')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('purchaseDetailsDescription3')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('purchaseDetailsDescription4')} />
            </ListItem>
          </List>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseDateStartLicense')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={formData.startDate === '' ? formatCurrentDate : formData.startDate}
            registration={register('startDate')}
            error={showErrors ? 'startDate' in errors : false}
            helperText={showErrors ? errors.startDate?.message : ''}
            label=""
            variant="outlined"
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
        <TGGrid item md={2} xs={3}>
          {}
        </TGGrid>
        <TGGrid item md={10} xs={9}>
          <List>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDateStartLicenseDescription1')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDateStartLicenseDescription2')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDateStartLicenseDescription3')} />
            </ListItem>
          </List>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('TrailDateEndLicense1')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem">
            {selectedEndDate2}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
        <TGGrid item md={2} xs={3}>
          {}
        </TGGrid>
        <TGGrid item md={10} xs={9}>
          <List>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDateEndLicenseDescription1')} />
            </ListItem>
          </List>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('TrailDateEndLicense2')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem">
            {selectedEndDate}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
        <TGGrid item md={2} xs={3}>
          {}
        </TGGrid>
        <TGGrid item md={10} xs={9}>
          <List>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDateEndLicenseDescription2')} />
            </ListItem>
          </List>
        </TGGrid>
      </TGGrid>
      <TGGrid container mt={5} mb={3} display="flex" justifyContent="right">
        <TGGrid item md={8} sm={11}>
          <Box display="flex" alignItems="center" justifyContent="right">
            <Button
              variant="contained"
              sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
              className="purchase-button-gray"
              onClick={handleOpenDialog}
            >
              {t('purchaseEmail.button.cancel')}
            </Button>
            <Button
              variant="contained"
              sx={{ marginLeft: '20px', width: '120px', fontSize: '14px' }}
              onClick={handleFormSubmit}
            >
              {t('Trail.button.register')}
            </Button>
          </Box>
        </TGGrid>
      </TGGrid>
      <DialogConfirmCancel open={open} onClose={handleCloseDialog} onConfirm={handleConfirmation} />
    </>
  );
}

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { ReactNode, useEffect, useState } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Autocomplete, Button, IconButton, Paper, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../../constants/app';
import { getGroups } from '../../../state/actions/group';
import { start } from '../../../lib/saga-promise';
import roles from '../../../constants/roles';
import { exportCsv } from '../../../component/helpers/utility';
import SearchCriteria from '../../../component/Layout/SearchCriteria';
import SearchForm from '../../../component/Layout/SearchForm';
import SearchResult from '../../../component/Layout/SearchResult';
import GroupSelector from '../../../component/Form/GroupSelector';
import IsInvalidCheckbox from '../../../component/Form/IsInvalidCheckbox';
import DownloadButton from '../../../component/Form/DownloadButton';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput } from './types';
import TGGrid from '../../../component/Elements/TGGrid';
import IsIncludeExpiredRadio from '../../../component/Form/IsIncludeExpiredRadio';
import { searchLicenses, getStatusLicensesByUserLogin } from '../../../state/actions/license';
import { canLicenseBind, updateUserLicense } from '../../../state/actions/user';
import TGTextField from '../../../component/Elements/TGTextField';
import OverHideText from '../../../component/Elements/OverHideText';
import ImportButton from '../../../component/Form/ImportButton';
import routes from '../../../constants/routes';

const localStorageKey = 'searchUserLicense';

export default function LicenseUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { register, handleSubmit, setValue, reset, getValues, watch } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.license);
  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const history = useNavigate();
  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
    start(getStatusLicensesByUserLogin, null, dispatch);
    setValueFromLocalStorage();
    handleSubmit(searchSubmit)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    await start(searchLicenses, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setValueFromLocalStorage = () => {
    const searchLocalStorage = localStorage.getItem(localStorageKey);
    if (searchLocalStorage) {
      reset(JSON.parse(searchLocalStorage) as SearchCriteriaInput);
    }
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  const handleExportCsv = (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const url = `/license/export_user?groupId=${encodeURIComponent(
      data.groupId ?? ''
    )}&licenseCode=${encodeURIComponent(data.licenseCode)}&loginId=${encodeURIComponent(
      data.loginId
    )}&username=${encodeURIComponent(data.username)}&isIncludeExpired=${encodeURIComponent(
      data.isIncludeExpired
    )}&isInvalid=${encodeURIComponent(data.isInvalid)}&locale=${sessionStorage.getItem('locale')}`;
    exportCsv(url, 'UserLicense');
  };
  const handleReopenLicense = () => {
    const url = `/purchase/reopenLicenses/${encodeURIComponent(user.userId)}`;
    window.open(url, '_blank');
  };
  const handleAddLicense = () => {
    const url = `/purchase/buyAdditionalLicenses/${encodeURIComponent(user.userId)}`;
    window.open(url, '_blank');
  };
  const handleAddLicensePersonal = () => {
    const url = `/purchase/buyLicense/${encodeURIComponent(user.userId)}`;
    window.open(url, '_blank');
  };
  const handleUpdateStopLicense = () => {
    const url = `/purchase/updateStop/${encodeURIComponent(user.userId)}`;
    window.open(url, '_blank');
  };
  const handleStartLicense = () => {
    const url = `/purchase/startLicense/${encodeURIComponent(user.userId)}`;
    window.open(url, '_blank');
  };
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [editLicenseId, setEditLicenseId] = useState<string>('');
  const [bindLoginId, setBindLoginId] = useState<string | null>(null);
  const canBindUsers = useSelector((state: RootStateOrAny) => state.user.canBindUsers);

  const editBindUser = async (groupId: string, licenseId: string, loginId: string) => {
    await start(canLicenseBind, { groupId, licenseId, noLoading: true }, dispatch);
    setEditMode(true);
    setBindLoginId(loginId);
    setEditLicenseId(licenseId);
  };
  const cancelEditMode = () => {
    setEditMode(false);
    setEditLicenseId('');
  };
  const doneEditMode = async () => {
    await start(updateUserLicense, { licenseId: editLicenseId, loginId: bindLoginId, noLoading: true }, dispatch);
    setEditMode(false);
    setEditLicenseId('');
    handleSubmit(searchSubmit)();
  };
  const changeUser = (data: any) => {
    setBindLoginId(data?.loginId);
  };

  const handleClickOpen = () => {
    const url = `/purchase/changePaymentMethod/${encodeURIComponent(user.userId)}`;
    window.open(url, '_blank');
  };

  return (
    <SearchForm title={t('licenseUser.label.top')} description={t('licenseUser.label.description')}>
      {(user.roleId === 3 && user.isPersonalMng === true && rows.licenseTrialValid > 0 && groups?.[0]?.isAgent === false) && (
        <TGGrid item xs={12} sx={{ float: 'right', marginTop: '-30px' }}>
          <Button
            variant="contained"
            onClick={handleAddLicensePersonal}
            style={{
              marginRight: '2px',
            }}
          >
            {t('purchase.label.buttonBuyLicense')}
          </Button>
        </TGGrid>
      )}
      {(user.roleId === 2 && groups?.[0]?.isAgent === false) && (
        <TGGrid item xs={12} sx={{ float: 'right', marginTop: '-30px' }}>
          {((rows.licenseRegularValid > 0 && rows.licenseRegularExpried > 0) || (rows.licenseTrialValid > 0) || (rows.licenseRegularValid > 0) || (rows.licenseCount === 0)) && (
            <Button
              variant="contained"
              onClick={handleAddLicense}
              style={{
                marginRight: '2px',
              }}
            >
              {t('purchaseStepBuyAdditionalLicenses')}
            </Button>
          )}
          {/* {((rows.licenseRegularValid > 0 && rows.licenseRegularExpried > 0) || rows.licenseTrialExpried > 0 || rows.licenseRegularExpried > 0) && (
            <Button
              variant="contained"
              onClick={handleReopenLicense}
              style={{
                marginRight: '2px',
              }}
            >
              {t('reopenLicenses2')}
            </Button>
          )} */}
          {/* {((rows.licenseRegularValid > 0 && rows.licenseRegularExpried > 0) || rows.licenseRegularValid > 0) && (
            <Button
              variant="contained"
              onClick={handleUpdateStopLicense}
              style={{
                marginRight: '2px',
              }}
            >
              {t('licenseUser.button.stop.license')}
            </Button>
          )} */}
        </TGGrid>
      )}

      {(user.roleId === 2 || (user.roleId === 3 && user.isPersonalMng === true)) && (
      <TGGrid item xs={12} sx={{ float: 'right', marginTop: '-30px' }}>
        {((rows.licenseRegularValid > 0 && rows.licenseRegularExpried > 0 && groups?.[0]?.isAgent === false) || (rows.licenseRegularValid > 0 && groups?.[0]?.isAgent === false)) && (
        <Button
          variant="contained"
          onClick={handleUpdateStopLicense}
          style={{
            marginRight: '2px',
          }}
        >
          {t('licenseUser.button.stop.license')}
        </Button>
        )}
        { (rows.licenseStopCount > 0 && groups?.[0]?.isAgent === false) && (
        <Button
          variant="contained"
          onClick={handleStartLicense}
          style={{
            marginRight: '2px',
          }}
        >
          {t('licenseUser.button.start.license')}
        </Button>
        )}
        {((rows.licenseRegularValid > 0 && rows.licenseRegularExpried > 0 && groups?.[0]?.isAgent === false) || (rows.licenseTrialExpried > 0 && groups?.[0]?.isAgent === false) || (rows.licenseRegularExpried > 0 && groups?.[0]?.isAgent === false)) && (
        <Button
          variant="contained"
          onClick={handleReopenLicense}
          style={{
            marginRight: '2px',
          }}
        >
          {t('reopenLicenses2')}
        </Button>
        )}
        {(rows.licenseRegularValid > 0 && groups?.[0]?.isAgent === false && user.changePaymentMethodFlag) && (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{
            marginRight: '2px',
          }}
        >
          {t('changePaymentMethod')}
        </Button>
        )}
      </TGGrid>
      )}

      <TGGrid sx={{ mb: 2, mt: 2 }}>
        <SearchCriteria
          handleRefresh={() => {
            reset(SearchCriteriaDefaultInput);
            const searchLocalStorage = localStorage.getItem(localStorageKey);
            if (searchLocalStorage) {
              localStorage.removeItem(localStorageKey);
            }
          }}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={12} md={4} userRoleId={user.roleId} showRoles={[roles.systemAdmin]}>
            <GroupSelector
              groupId={watch('groupId')}
              handleChange={(data) => setValue('groupId', data?.groupId ?? null)}
              groups={groups}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <TGTextField registration={register('licenseCode')} label={t('common.label.licenseCode')} />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <TGTextField registration={register('loginId')} label={t('common.label.userid')} />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <TGTextField registration={register('username')} label={t('common.label.username')} />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <IsIncludeExpiredRadio
              handleChange={(e, value) => setValue('isIncludeExpired', value === 'true')}
              value={watch('isIncludeExpired')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <IsInvalidCheckbox registration={register('isInvalid')} checked={watch('isInvalid')} />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid item xs={12} sx={{ float: 'right', mb: 2 }}>
        <ImportButton
          handleClick={() => {
            history(routes.userLicenseImport);
          }}
        />
        <DownloadButton handleClick={handleSubmit(handleExportCsv)} />
      </TGGrid>

      <TGGrid>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.licenses}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'licenseId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'groupNameWithCode':
                return `${row.groupName} < ${row.groupCode} > `;
              case 'isInvalid':
                return value ? <LockOutlinedIcon color="error" /> : '';
              case 'licenseStatus':
                if (row.purchaseType === 'Expired') {
                  return (t('licenseStatusExpired'));
                }
                return (row.purchaseType === 'Trial') ? t('licenseStatusFreeTrial') : t('licenseStatusContracted');
              case 'renewFlag':
                if (row.purchaseType === 'Trial') {
                  return (t('common.label.noContract'));
                }
                if (row.renewFlag === false || row.isInvalid || row.purchaseType === 'Expired') {
                  return (t('licenseStatusContractedStop'));
                }
                return (t('common.label.renewFlag'));
              case 'UserNameWithLoginId':
                if (isEditMode && editLicenseId === row.licenseId) {
                  return (
                    <Autocomplete
                      disablePortal
                      sx={{ minWidth: '250px' }}
                      size="small"
                      options={canBindUsers}
                      isOptionEqualToValue={() => true}
                      getOptionLabel={(option: any) => `${option.username} <${option.loginId}>`}
                      noOptionsText={t('licenseUser.msg.notFound')}
                      onChange={(e, obj) => {
                        changeUser(obj);
                      }}
                      value={canBindUsers.find((item: any) => item.loginId === bindLoginId) ?? null}
                      renderInput={(params) => <TextField {...params} name="groupId" />}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      PaperComponent={(props: any) => <Paper elevation={16} {...props} />}
                    />
                  );
                }
                if (row.loginId) {
                  return `${row.userName} < ${row.loginId} > `;
                }
                return '';

              case 'action':
                if (isEditMode && editLicenseId === row.licenseId) {
                  return (
                    <>
                      <IconButton color="success" onClick={doneEditMode}>
                        <DoneOutlineIcon />
                      </IconButton>
                      <IconButton color="error" onClick={cancelEditMode}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  );
                }
                return (
                  <Button
                    onClick={() => editBindUser(row.groupId, row.licenseId, row.loginId)}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={(isEditMode && editLicenseId !== row.licenseId) || user.roleId === 3}
                  >
                    {t('common.btn.edit')}
                  </Button>
                );
              case 'endDate':
                if (row.purchaseType === 'Expired') {
                  if (moment.utc(row.endDate).isBefore(moment())) {
                    return moment.utc(row.endDate).format('YYYY/MM/DD');
                  }
                  if (moment.utc(row.endDateTrail).isBefore(moment())) {
                    return moment.utc(row.endDateTrail).format('YYYY/MM/DD');
                  }
                }
                return (row.purchaseType === 'Trial') ? moment.utc(row.endDateTrail).format('YYYY/MM/DD') : moment.utc(row.endDate).format('YYYY/MM/DD');
              default:
                return column.format ? column.format(value) : <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
    </SearchForm>
  );
}

import { createTheme, ThemeOptions } from '@mui/material/styles';

import colors from '../assets/scss/_themes-vars.module.scss';

import themePalette from './palette';
// import themeTypography from './typography';
import componentStyleOverrides from './compStyleOverride';
import themeTypography from './typography';

let glbThemeOption: any;

export const getThemeOption = () => glbThemeOption;

declare module '@mui/material/styles' {
  interface Palette {
    greyBold: Palette['primary'];
    grayLight: Palette['primary'];
  }
  interface PaletteOptions {
    greyBold?: PaletteOptions['primary'];
  }
  interface PaletteOptions {
    grayLight?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    greyBold: true;
  }

  interface ButtonPropsColorOverrides {
    grayLight: true;
  }
}

const theme = (customization: any) => {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey900,
    darkTextSecondary: color.grey700,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization,
  };

  glbThemeOption = themeOption;

  const themeOptions: ThemeOptions = {
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: '38px',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        '@media (min-width: 600px)': {
          minHeight: '38px',
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);

  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { t } from 'i18next';

type Props = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  value: any;
};

export default function IsIncludeExpiredRadio({ handleChange, value }: Props) {
  return (
    <FormControl size="small">
      <FormLabel>{t('IsIncludeExpiredRadio.label.description')}</FormLabel>
      <RadioGroup row defaultValue="true" name="isIncludeExpired" onChange={handleChange} value={value}>
        <FormControlLabel value="true" control={<Radio />} label={t('IsIncludeExpiredRadio.radio.Include') as string} />
        <FormControlLabel value="false" control={<Radio />} label={t('IsIncludeExpiredRadio.radio.NotInclude') as string} />
      </RadioGroup>
    </FormControl>
  );
}

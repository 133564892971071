import { CircularProgress } from '@mui/material';

interface IProps {
  loading: boolean | false,
  type: 'full' | 'block'
}

export default function Index({ loading, type = 'full' }: IProps) {
  if (!loading) return null;
  return (
    <div className={`loading__${type}`}>
      <CircularProgress size={50} />
    </div>
  );
}

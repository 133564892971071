import { Dialog, DialogActions, DialogContent, Button, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';


import React, { useEffect, useState } from 'react';

interface CodeInputProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (code: string) => void;
  numInputs: number
}

function CodeInput({ open, onClose, onConfirm, numInputs }: CodeInputProps) {
  // Inline CSS styles
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
  };

  const inputStyle: React.CSSProperties = {
    width: '50px',
    height: '50px',
    margin: '0 5px',
    fontSize: '24px',
    textAlign: 'center',
    border: '2px solid #ddd',
    borderRadius: '8px',
    transition: 'border-color 0.2s',
  };

  const inputFocusStyle: React.CSSProperties = {
    borderColor: '#007bff',
    outline: 'none',
  };

  const [code, setCode] = useState<string[]>(Array(numInputs).fill(''));
  const [isChecked, setIsChecked] = useState(false);

  const { t } = useTranslation();
  const handleCancel = () => {
    onClose();
  };
  const handleConfirm = () => {
    onConfirm(code.join(''));
  };

  useEffect(() => {
    if (open) {
      setCode(Array(numInputs).fill(''));
      setIsChecked(false);
    }
  }, [open, numInputs]);

  const checkCodeLength = (codeInput: string) => {
    if (codeInput.length === 8) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === '') {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      checkCodeLength(newCode.join(''));
      if (value && index < numInputs - 1) {
        const nextInput = document.getElementById(`code-input-${index + 1}`);
        if (nextInput) (nextInput as HTMLInputElement).focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && code[index] === '') {
      if (index > 0) {
        const prevInput = document.getElementById(`code-input-${index - 1}`);
        if (prevInput) (prevInput as HTMLInputElement).focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text');
    if (/^[0-9]+$/.test(pasteData)) {
      const newCode = [...code];
      const pasteArray = pasteData.split('').slice(0, numInputs);
      pasteArray.forEach((digit, i) => {
        newCode[i] = digit;
      });
      setCode(newCode);
      checkCodeLength(newCode.join(''));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key } = e;
    if (/^[0-9]$/.test(key)) {
      const newCode = [...code];
      newCode[index] = key;
      setCode(newCode);
      checkCodeLength(newCode.join(''));
      if (index < numInputs - 1) {
        const nextInput = document.getElementById(`code-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };



  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>{t('title.code.verify')}</DialogContentText>
      </DialogContent>
      <DialogContent>
        <div style={containerStyle}>
          {code.map((digit: string, index: number) => (
            <input
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              id={`code-input-${index}`}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onKeyPress={(e) => handleKeyPress(e, index)}
              onPaste={handlePaste}
              style={inputStyle}
              onFocus={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (e.target as HTMLInputElement).style.borderColor = inputFocusStyle.borderColor!;
              }}
              onBlur={(e) => {
                (e.target as HTMLInputElement).style.borderColor = '#ddd';
              }}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="purchase-button-gray" onClick={handleCancel}>
          {t('btnNo')}
        </Button>
        <Button variant="contained" onClick={handleConfirm} autoFocus disabled={!isChecked}>
          {t('btnYes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CodeInput;

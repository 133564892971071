// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class LicenseServices {
  static createLicense(params: any) {
    const url = 'license/create';
    return axiosClient.post(url, params);
  }

  static editLicense(params: any) {
    const url = 'license/edit';
    return axiosClient.put(url, params);
  }

  static searchLicenses(params: any) {
    const url = 'license/search';
    return axiosClient.get(url, { params });
  }

  static mailLicensesPayment(params: any) {
    const url = 'license/mailLicensePayment';
    return axiosClient.get(url, { params });
  }

  static mailLicensesEndDateTrial10Day(params: any) {
    const url = 'license/mailEndDateTrial10day';
    return axiosClient.get(url, { params });
  }

  static sendMailLicensesEndDateTrial10Day(params: any) {
    const url = 'license/sendMailEndDateTrial10Day';
    return axiosClient.post(url, params);
  }

  static mailLicenseEndDate60day(params: any) {
    const url = 'license/mailEndDate60day';
    return axiosClient.get(url, { params });
  }

  static sendMailLicensesEndDate60Day(params: any) {
    const url = 'license/sendMailEndDate60Day';
    return axiosClient.post(url, params);
  }

  static sendMailRegisterLicense(params: any) {
    const url = 'license/sendMailRegisterLicense';
    return axiosClient.post(url, params);
  }

  static sendMailLicensePayment(params: any) {
    const url = 'license/sendMailLicensePayment';
    return axiosClient.post(url, params);
  }

  static searchLicensesInPurchase(params: any) {
    const url = 'license/purchase/search';
    return axiosClient.get(url, { params });
  }

  static getLicensesByUser(params: any) {
    const url = 'license/purchase/user';
    return axiosClient.get(url, { params });
  }

  static getListLicenseStop(params: any) {
    const url = 'license/purchase/licenseStop';
    return axiosClient.get(url, { params });
  }

  static getLicensesTrialByUser(params: any) {
    const url = 'license/licenseTrial';
    return axiosClient.get(url, { params });
  }

  static getLicensesByTemporaryId(params: any) {
    const url = 'license/purchase/temporaryId';
    return axiosClient.get(url, { params });
  }

  static editStatusLicenses(params: any) {
    const url = 'license/update/status';
    return axiosClient.put(url, params);
  }

  static updateStopLicensesStatus(params: any) {
    const url = 'license/updateStop';
    return axiosClient.put(url, params);
  }

  static updateStartLicensesStatus(params: any) {
    const url = 'license/startLicense';
    return axiosClient.put(url, params);
  }

  static reopenLicensesStatus(params: any) {
    const url = 'license/reopen';
    return axiosClient.put(url, params);
  }

  static licenseDetail(params: any) {
    const url = 'license/detail';
    return axiosClient.get(url, { params });
  }

  static licenseDetailInPurchase(params: any) {
    const url = 'license/purchase/detail';
    return axiosClient.get(url, { params });
  }

  static licenseImportUser(params: any) {
    const url = 'license/import/resgister_user';
    return axiosClient.post(url, params, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  static licenseNewKey(params: any) {
    const url = 'license/NewKey';
    return axiosClient.get(url, { params });
  }

  static getStatusLicensesByUserLogin() {
    const url = 'license/statusLicense';
    return axiosClient.get(url);
  }

  static getRenewalDate(params: any) {
    const url = 'license/getRenewalDate';
    return axiosClient.get(url, { params });
  }

  static getLicensesBankUpdate(params: any) {
    const url = 'license/getLicensesBankUpdate';
    return axiosClient.get(url, { params });
  }

  static updateLicensesBank(params: any) {
    const url = 'license/updateLicensesBank';
    return axiosClient.put(url, params);
  }

  static changePaymentMethod(params: any) {
    const url = 'license/changePaymentMethod';
    return axiosClient.post(url, params);
  }

  static getChangePaymentMethodFlag() {
    const url = 'user/getChangePaymentMethodFlag';
    return axiosClient.get(url);
  }
}
export default LicenseServices;

/* eslint-disable react/require-default-props */
import { Typography } from '@mui/material';

type Props = {
  children: React.ReactNode;
  maxWidth?: number;
};

export default function OverHideText({ children, maxWidth = 200 }: Props) {
  return (
    <Typography
      variant="inherit"
      sx={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth,
        display: 'block',
        overflow: 'hidden',
      }}
    >
      {children}
    </Typography>
  );
}

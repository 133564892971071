export const types = {
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  EDIT_USER: 'EDIT_USER',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  SEARCH_MUSER: 'SEARCH_MUSER',
  SEARCH_MUSER_SUCCESS: 'SEARCH_MUSER_SUCCESS',
  SEARCH_USER: 'SEARCH_USER',
  SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
  USER_DETAIL: 'USER_DETAIL',
  USER_DETAIL_SUCCESS: 'USER_DETAIL_SUCCESS',
  PASSWORD_RESET: 'PASSWORD_RESET',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
  PASSWORD_CHANGE_SUCCESS: 'PASSWORD_CHANGE_SUCCESS',
  CAN_LICENSE_BIND: 'CAN_LICENSE_BIND',
  CAN_LICENSE_BIND_SUCCESS: 'CAN_LICENSE_BIND_SUCCESS',
  UPDATE_USER_LICENSE: 'UPDATE_USER_LICENSE',
  UPDATE_USER_LICENSE_SUCCESS: 'UPDATE_USER_LICENSE_SUCCESS',
  PASSWORD_EXPIRED_CHANGE: 'PASSWORD_EXPIRED_CHANGE',
  PASSWORD_EXPIRED_CHANGE_SUCCESS: 'PASSWORD_EXPIRED_CHANGE_SUCCESS',
};

export const creatUser = (payload: any) => ({
  type: types.CREATE_USER,
  payload
});

export const creatUserSuccess = (payload: any) => ({
  type: types.CREATE_USER_SUCCESS,
  payload
});

export const editUser = (payload: any) => ({
  type: types.EDIT_USER,
  payload
});

export const editUserSuccess = (payload: any) => ({
  type: types.EDIT_USER_SUCCESS,
  payload
});

export const searchMUser = (payload: any) => ({
  type: types.SEARCH_MUSER,
  payload
});

export const searchMUserSuccess = (payload: any) => ({
  type: types.SEARCH_MUSER_SUCCESS,
  payload
});

export const searchUser = (payload: any) => ({
  type: types.SEARCH_USER,
  payload
});

export const searchUserSuccess = (payload: any) => ({
  type: types.SEARCH_USER_SUCCESS,
  payload
});

export const userDetail = (payload: any) => ({
  type: types.USER_DETAIL,
  payload
});

export const userDetailSuccess = (payload: any) => ({
  type: types.USER_DETAIL_SUCCESS,
  payload
});

export const canLicenseBind = (payload: any) => ({
  type: types.CAN_LICENSE_BIND,
  payload
});

export const canLicenseBindSuccess = (payload: any) => ({
  type: types.CAN_LICENSE_BIND_SUCCESS,
  payload
});

export const passwordReset = (payload: any) => ({
  type: types.PASSWORD_RESET,
  payload
});

export const passwordResetSuccess = (payload: any) => ({
  type: types.PASSWORD_RESET_SUCCESS,
  payload
});

export const passwordChange = (payload: any) => ({
  type: types.PASSWORD_CHANGE,
  payload
});

export const passwordChangeSuccess = (payload: any) => ({
  type: types.PASSWORD_CHANGE_SUCCESS,
  payload
});

export const updateUserLicense = (payload: any) => ({
  type: types.UPDATE_USER_LICENSE,
  payload
});

export const updateUserLicenseSuccess = (payload: any) => ({
  type: types.UPDATE_USER_LICENSE_SUCCESS,
  payload
});

export const passwordExpiredChange = (payload: any) => ({
  type: types.PASSWORD_EXPIRED_CHANGE,
  payload
});

export const passwordExpiredChangeSuccess = (payload: any) => ({
  type: types.PASSWORD_EXPIRED_CHANGE_SUCCESS,
  payload
});

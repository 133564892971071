import { call, put, takeLatest } from 'redux-saga/effects';
import { sagaPromise } from '../../lib/saga-promise';
import { changeLoading } from '../actions/app';
import GroupPasswordPolicyServices from '../../services/group_password_policy';
import { types, createGroupPasswordPolicySuccess, editGroupPasswordPolicySuccess, groupPasswordPolicyDetailSuccess } from '../actions/group_password_policy';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}

interface Iaction {
  type: string,
  payload: any,
}

function* createGroupPasswordPolicy(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    // eslint-disable-next-line max-len
    const data: ResponseGenerator = yield call(GroupPasswordPolicyServices.createGroupPasswordPolicy, action.payload);
    yield put(createGroupPasswordPolicySuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* editGroupPasswordPolicy(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    // eslint-disable-next-line max-len
    const data: ResponseGenerator = yield call(GroupPasswordPolicyServices.editGroupPasswordPolicy, action.payload);
    yield put(editGroupPasswordPolicySuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}
function* groupPasswordPolicyDetail(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      // yield put(changeLoading(true));
    }
    // eslint-disable-next-line max-len
    const data: ResponseGenerator = yield call(GroupPasswordPolicyServices.groupPasswordPolicyDetail, action.payload);
    yield put(groupPasswordPolicyDetailSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

export default function* groupPasswordPolicySaga() {
  yield takeLatest(types.CREATE_GROUP_PASSWORD_POLICY, sagaPromise(createGroupPasswordPolicy));
  yield takeLatest(types.EDIT_GROUP_PASSWORD_POLICY, sagaPromise(editGroupPasswordPolicy));
  yield takeLatest(types.GROUP_PASSWORD_POLICY_DETAIL, sagaPromise(groupPasswordPolicyDetail));
}

// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class GroupServices {
  static createGroup(params: any) {
    const url = 'group/create';
    return axiosClient.post(url, params);
  }

  static editGroup(params: any) {
    const url = 'group/edit';
    return axiosClient.put(url, params);
  }

  static getGroups(params: any) {
    const url = 'group/select';
    return axiosClient.get(url, { params });
  }

  static searchGroups(params: any) {
    const url = 'group/search';
    return axiosClient.get(url, { params });
  }

  static groupDetail(params: any) {
    const url = 'group/detail';
    return axiosClient.get(url, { params });
  }
}
export default GroupServices;

import { useNavigate } from 'react-router-dom';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { changeActiveMenu } from '../../../../../state/actions/app';

function NavItem({ item, level }: any) {
  const history = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const menuActive = useSelector((state: RootStateOrAny) => state.app.menuActive);

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: menuActive === item.id ? 8 : 6,
        height: menuActive === item.id ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  const itemHandler = (url: string, id: string) => {
    history(url);
    dispatch(changeActiveMenu(id));
  };

  return (
    <ListItemButton
      disabled={item.disabled}
      sx={{
        borderRadius: '5px',
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
      }}
      selected={menuActive === item.id}
      onClick={() => itemHandler(item.url, item.id)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
      <ListItemText
        primary={(
          <Typography variant={menuActive === item.id ? 'h5' : 'body1'} color="inherit">
            {t(item.title)}
          </Typography>
        )}
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
}

export default NavItem;

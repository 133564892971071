import { types } from '../actions/device';

interface GroupRequest {
  type: string;
  payload?: any;
}

type GroupActions = GroupRequest;

interface IGroupState {
  device: any;
  error: string | null;
  devices: any;
  total: number;
}

const initialState: IGroupState = {
  device: [],
  error: null,
  devices: [],
  total: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: GroupActions) => {
  switch (type) {
    case types.CREATE_DEVICE_SUCCESS:
      return {
        ...state,
      };
    case types.SEARCH_DEVICE_SUCCESS:
      state.devices = payload.list;
      state.total = payload.count;
      return {
        ...state,
      };
    case types.DEVICE_DETAIL_SUCCESS:
      state.device = payload;
      return {
        ...state,
      };
    case types.EDIT_DEVICE_SUCCESS:
    case types.CANCEL_DEVICE_SUCCESS:
    case types.RESET_CANCEL_DEVICE_SUCCESS: {
      const index = state.devices.findIndex((item: any) => item.deviceId === payload.deviceId);
      if (index >= 0) {
        state.devices[index] = payload;
        state.device = payload;
      }
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

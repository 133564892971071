import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import TGGrid from '../../component/Elements/TGGrid';
import PurchaseForm from '../../component/Layout/PurchaseForm';
import routes from '../../constants/routes';

function ChangePaymentMethodSuccess() {
  const user = useSelector((state: RootStateOrAny) => state.auth.user);

  if (user && !user.changePaymentMethodFlag) {
    window.location.href = routes.pageNotFound;
  }

  const currentPaymentMethod = localStorage.getItem('currentPaymentMethod');
  const { t } = useTranslation();


  return (
    <div className="purchase__container">
      <PurchaseForm>
        <TGGrid container columns={12} mt={8} ml={4}>
          <TGGrid item xs={12} style={{ margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h2" mb={4}>{t('changePaymentMethodSuccess')}</Typography>
            <img src={`/${currentPaymentMethod}-icon.svg`} alt="" style={{ width: '160px' }} />
            <Typography mt={4} variant="h3">{t(`currentPaymentMethodIs${currentPaymentMethod}`)}</Typography>
          </TGGrid>
        </TGGrid>
      </PurchaseForm>
    </div>
  );
}

export default ChangePaymentMethodSuccess;

/* eslint-disable react/require-default-props */
import { Grid, GridProps } from '@mui/material';

type Props = GridProps & {
  showRoles?: number[];
  userRoleId?: number;
};

export default function TGGrid({ showRoles, userRoleId, ...props }: Props) {
  if (userRoleId && showRoles) {
    if (showRoles.includes(userRoleId)) {
      return <Grid {...props}>{props.children}</Grid>;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return <Grid {...props}>{props.children}</Grid>;
}

// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class DeviceServices {
  static createDevice(params: any) {
    const url = 'device/create';
    return axiosClient.post(url, params);
  }

  static editDevice(params: any) {
    const url = 'device/edit';
    return axiosClient.put(url, params);
  }

  static cancelDevice(params: any) {
    const url = 'device/cancel';
    return axiosClient.put(url, params);
  }

  static resetCancelDevice(params: any) {
    const url = 'device/reset_cancel';
    return axiosClient.put(url, params);
  }

  static searchDevice(params: any) {
    const url = 'device/search';
    return axiosClient.get(url, { params });
  }

  static deviceDetail(params: any) {
    const url = 'device/detail';
    return axiosClient.get(url, { params });
  }
}
export default DeviceServices;

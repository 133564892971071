export const types = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ADMIN: 'LOGIN_ADMIN',
  LOGIN_ADMIN_SUCCESS: 'LOGIN_ADMIN_SUCCESS',
  REMIND: 'REMIND',
  REMIND_SUCCESS: 'REMIND_SUCCESS',
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_TOKEN: 'GET_TOKEN',
  GET_TOKEN_SUCCESS: 'GET_TOKEN_SUCCESS',
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',
  LOGOUT: 'LOGOUT'
};

export const login = (payload: any) => ({
  type: types.LOGIN,
  payload
});

export const loginSuccess = (payload: any) => ({
  type: types.LOGIN_SUCCESS,
  payload
});
export const loginAdmin = (payload: any) => ({
  type: types.LOGIN_ADMIN,
  payload
});

export const loginAdminSuccess = (payload: any) => ({
  type: types.LOGIN_ADMIN_SUCCESS,
  payload
});

export const remind = (payload: any) => ({
  type: types.REMIND,
  payload
});

export const remindSuccess = (payload: any) => ({
  type: types.REMIND_SUCCESS,
  payload
});

export const getProfile = () => ({
  type: types.GET_PROFILE,
});

export const getProfileSuccess = (payload: any) => ({
  type: types.GET_PROFILE_SUCCESS,
  payload
});

export const getToken = (payload: any) => ({
  type: types.GET_TOKEN,
  payload
});

export const getTokenSuccess = (payload: any) => ({
  type: types.GET_TOKEN_SUCCESS,
  payload
});

export const getProfileError = () => ({
  type: types.GET_PROFILE_ERROR
});

export const logout = () => ({
  type: types.LOGOUT
});

export const types = {
  CREATE_DEVICE: 'CREATE_DEVICE',
  CREATE_DEVICE_SUCCESS: 'CREATE_DEVICE_SUCCESS',
  EDIT_DEVICE: 'EDIT_DEVICE',
  EDIT_DEVICE_SUCCESS: 'EDIT_DEVICE_SUCCESS',
  CANCEL_DEVICE: 'CANCEL_DEVICE',
  CANCEL_DEVICE_SUCCESS: 'CANCEL_DEVICE_SUCCESS',
  RESET_CANCEL_DEVICE: 'RESET_CANCEL_DEVICE',
  RESET_CANCEL_DEVICE_SUCCESS: 'RESET_CANCEL_DEVICE_SUCCESS',
  SEARCH_DEVICE: 'SEARCH_DEVICE',
  SEARCH_DEVICE_SUCCESS: 'SEARCH_DEVICE_SUCCESS',
  DEVICE_DETAIL: 'DEVICE_DETAIL',
  DEVICE_DETAIL_SUCCESS: 'DEVICE_DETAIL_SUCCESS',
};

export const createDevice = (payload: any) => ({
  type: types.CREATE_DEVICE,
  payload
});

export const createDeviceSuccess = (payload: any) => ({
  type: types.CREATE_DEVICE_SUCCESS,
  payload
});

export const editDevice = (payload: any) => ({
  type: types.EDIT_DEVICE,
  payload
});

export const editDeviceSuccess = (payload: any) => ({
  type: types.EDIT_DEVICE_SUCCESS,
  payload
});

export const cancelDevice = (payload: any) => ({
  type: types.CANCEL_DEVICE,
  payload
});

export const cancelDeviceSuccess = (payload: any) => ({
  type: types.CANCEL_DEVICE_SUCCESS,
  payload
});

export const resetCancelDevice = (payload: any) => ({
  type: types.RESET_CANCEL_DEVICE,
  payload
});

export const resetCancelDeviceSuccess = (payload: any) => ({
  type: types.RESET_CANCEL_DEVICE_SUCCESS,
  payload
});

export const searchDevice = (payload: any) => ({
  type: types.SEARCH_DEVICE,
  payload
});

export const searchDeviceSuccess = (payload: any) => ({
  type: types.SEARCH_DEVICE_SUCCESS,
  payload
});

export const deviceDetail = (payload: any) => ({
  type: types.DEVICE_DETAIL,
  payload
});

export const deviceDetailSuccess = (payload: any) => ({
  type: types.DEVICE_DETAIL_SUCCESS,
  payload
});

import { types } from '../actions/group';

interface GroupRequest {
  type: string;
  payload?: any;
}

type GroupActions = GroupRequest;

interface IGroupState {
  group: any;
  error: string | null;
  groups: any;
  allGroups: any;
  total: number;
}

const initialState: IGroupState = {
  group: [],
  error: null,
  groups: [],
  allGroups: [],
  total: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: GroupActions) => {
  switch (type) {
    case types.CREATE_GROUP_SUCCESS:
      state.group = payload;
      return {
        ...state,
      };
    case types.GET_GROUPS_SUCCESS:
      state.allGroups = payload;
      return {
        ...state,
      };
    case types.SEARCH_GROUPS_SUCCESS:
      state.groups = payload.list;
      state.total = payload.count;
      return {
        ...state,
      };
    case types.GROUP_DETAIL_SUCCESS:
      state.group = payload;
      return {
        ...state,
      };
    case types.EDIT_GROUP_SUCCESS: {
      const index = state.groups.findIndex((item: any) => item.groupId === payload.groupId);
      if (index >= 0) {
        state.groups[index] = payload;
        state.group = payload;
      }
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

/* eslint-disable func-names */
export const promiseDispath = (
  func: any,
  params: any,
  dispacth: any
  // eslint-disable-next-line no-promise-executor-return
) => new Promise((resolve, reject) => dispacth(func({ ...params, resolve, reject })));

export const start = (
  action: any,
  params: any,
  dispatch: any
) => promiseDispath(action, params, dispatch);

function resoveResult(action: any, result: any) {
  if (action?.resolve && typeof action.resolve === 'function') {
    action.resolve(result);
  }
}

function rejectResult(action: any, result: any) {
  if (action?.reject && typeof action.resolve === 'function') {
    action.reject(result);
  }
}

export function sagaPromise(handler: any) {
  return function* (action: any) {
    try {
      let newAction = null;
      if (action.payload) {
        newAction = {
          ...action,
          payload: Object.keys(action.payload).filter((key) => (key !== 'resolve') && (key !== 'reject'))
            .reduce((obj: any, key: any) => {
              obj[key] = action.payload[key];
              return obj;
            }, {})
        };
      } else {
        newAction = {
          ...action,
        };
      }
      const result = yield* handler(newAction);
      resoveResult(action?.payload, result);
    } catch (error) {
      rejectResult(action?.payload, error);
    }
  };
}

import { Avatar, Box, ButtonBase, useTheme } from '@mui/material';
import { IconMenu2 } from '@tabler/icons';
import { Link } from 'react-router-dom';
import Logo from './logo';
import customTheme from '../../../../themes/custom';
import ProfileSection from './profile';
import routes from '../../../../constants/routes';

function Header({ handleLeftDrawerToggle }: any) {
  const theme = useTheme();
  const custom = customTheme();
  return (
    <>
      <Box
        sx={{
          width: 260,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <ButtonBase disableRipple component={Link} to={routes.dashboard}>
            <Logo />
          </ButtonBase>
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...custom.commonAvatar,
              ...custom.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              '&:hover': {
                background: theme.palette.primary.dark,
                color: theme.palette.primary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <ProfileSection />
    </>
  );
}

export default Header;

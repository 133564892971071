import moment from 'moment';
import { DEFAULT_PAGE } from '../../../constants/app';
import { TableColumn } from '../../../types';

export type Column = TableColumn & {
  id:
    | 'licenseId'
    | 'groupNameWithCode'
    | 'licenseCode'
    | 'availableDeviceNumber'
    | 'startDate'
    | 'endDate'
    | 'UserNameWithLoginId'
    | 'action'
    | 'licenseStatus'
    | 'renewFlag'
    | 'isInvalid';
};
export const columns: Column[] = [
  {
    id: 'licenseId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'groupNameWithCode',
    label: 'common.label.groupnameGroupid',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
  },
  {
    id: 'availableDeviceNumber',
    label: 'common.label.availableDeviceNumber',
    align: 'center',
  },
  {
    id: 'startDate',
    label: 'common.label.startDate',
    format: (value: Date) => moment.utc(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'endDate',
    label: 'common.label.endDate',
    // format: (value: Date) => moment.utc(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'UserNameWithLoginId',
    label: 'common.label.userNameWithLoginId',
  },
  {
    id: 'action',
    label: '',
    align: 'center',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
  {
    id: 'licenseStatus',
    label: 'common.label.licenseStatus',
    align: 'center',
  },
  {
    id: 'renewFlag',
    label: 'common.title.renewFlag',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  groupId: string | null;
  licenseCode: string;
  loginId: string;
  username: string;
  isIncludeExpired: boolean;
  isInvalid: boolean;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  groupId: '',
  licenseCode: '',
  loginId: '',
  username: '',
  isIncludeExpired: true,
  isInvalid: false,
  pageIndex: DEFAULT_PAGE,
};

import { call, put, takeLatest } from 'redux-saga/effects';
import GroupServices from '../../services/group';
import { types, getGroupSuccess, searchGroupsSuccess, groupDetailSuccess, editGroupSuccess, createGroupSuccess } from '../actions/group';
import { sagaPromise } from '../../lib/saga-promise';
import { changeLoading } from '../actions/app';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}

interface Iaction {
  type: string,
  payload: any,
}

function* createGroup(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(GroupServices.createGroup, action.payload);
    yield put(createGroupSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* editGroup(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(GroupServices.editGroup, action.payload);
    yield put(editGroupSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getGroups(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      // yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(GroupServices.getGroups, action.payload);
    yield put(getGroupSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* searchGroups(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(GroupServices.searchGroups, action.payload);
    yield put(searchGroupsSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* groupDetail(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      // yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(GroupServices.groupDetail, action.payload);
    yield put(groupDetailSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}



export default function* groupSaga() {
  yield takeLatest(types.CREATE_GROUP, sagaPromise(createGroup));
  yield takeLatest(types.EDIT_GROUP, sagaPromise(editGroup));
  yield takeLatest(types.GET_GROUPS, sagaPromise(getGroups));
  yield takeLatest(types.SEARCH_GROUPS, sagaPromise(searchGroups));
  yield takeLatest(types.GROUP_DETAIL, sagaPromise(groupDetail));
}

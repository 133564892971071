/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Typography, Divider, Button, Alert, Box, Checkbox } from '@mui/material';
import React, { ReactNode, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { UnpackNestedValue, useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { start } from '../../../lib/saga-promise';
import { ColumnUpDateStopLicense, columns2, SearchLicenseRenew, SearchLicenseRenewDefault } from './types';
import PurchaseForm from '../../../component/Layout/PurchaseForm';
import TGGrid from '../../../component/Elements/TGGrid';
import SearchResult from '../../../component/Layout/SearchResult';
import { getListLicenseStop, updateStartLicensesStatus } from '../../../state/actions/license';
import OverHideText from '../../../component/Elements/OverHideText';
import { SIZE_PAGE } from '../../../constants/app';
import { PRICE_LICENSE } from '../../../constants/prices';
import routes from '../../../constants/routes';
import DialogConfirmCancel from '../confirmCancel';
import DialogConfirmStop from '../DialogConfirmStop';

function StartLicense() {
  const [rows, setRows] = useState<any | null>();
  const getLicense = async (data: UnpackNestedValue<SearchLicenseRenew>) => {
    const dataInput = {
      ...data,
      userId: param.userId,
      purchaseType: 'Paid',
    };
    const licenses: any = await start(getListLicenseStop, dataInput, dispatch);
    setRows(licenses);
    const calculatedMoney = (licenses.count - selectedRows.length) * PRICE_LICENSE;
    setTotalAmount(calculatedMoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  };
  const param = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit, getValues, setValue } = useForm<SearchLicenseRenew>({
    defaultValues: SearchLicenseRenewDefault,
  });
  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(getLicense)();
  };

  useEffect(() => {
    handleSubmit(getLicense)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isNotSelectedRows, setIsNotSelectedRows] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalAmount, setTotalAmount] = useState('0');
  const [isChecked, setIsChecked] = useState(false);
  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, rowId: string) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
      setIsNotSelectedRows(isNotSelectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
      setIsNotSelectedRows([...isNotSelectedRows, rowId]);
    }
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (rows !== undefined) {
      const calculatedMoney = (rows.count - selectedRows.length) * PRICE_LICENSE;
      setTotalAmount(calculatedMoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotSelectedRows]);

  const [codeError, setCodeError] = useState<string>('');
  const [open, setOpen] = useState(false);
  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleConfirmation = (confirm: boolean) => {
    if (confirm) {
      navigate(routes.licenseUser);
    }
  };

  const onSubmit: SubmitHandler<any> = async () => {
    try {
      const extensionLicenses = {
        userId: param.userId,
        listLicense: isNotSelectedRows,
        listLicenseNotBuy: selectedRows,
      };
      const urlPayment: any = await start(updateStartLicensesStatus, extensionLicenses, dispatch);
      setCodeError('');
      if (typeof param.userId === 'string') {
        if (urlPayment !== 'updateSuccess') {
          window.location.href = urlPayment;
        } else {
          // eslint-disable-next-line no-alert
          alert(t('lable.renewal.license'));
          navigate(routes.dashboard);
        }
      }
    } catch (error: any) {
      // eslint-disable-next-line no-empty
    }
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  // mouse event in dialog
  const [showErrors, setShowErrors] = useState(true);
  const handleOpenDialogConfirm = async () => {
    if (selectedRows.length === rows.count) {
      try {
        const extensionLicenses = {
          userId: param.userId,
          listLicense: isNotSelectedRows,
          listLicenseNotBuy: selectedRows,
        };
        const urlPayment: any = await start(updateStartLicensesStatus, extensionLicenses, dispatch);
        setCodeError('');
        if (typeof param.userId === 'string') {
          if (urlPayment !== 'updateSuccess') {
            window.location.href = urlPayment;
          } else {
            // eslint-disable-next-line no-alert
            alert(t('lable.renewal.license'));
            navigate(routes.dashboard);
          }
        }
      } catch (error: any) {
        // eslint-disable-next-line no-empty
      }
    } else {
      setOpenConfirm(true);
    }
  };
  const handleCloseDialogConfirm = () => {
    setOpenConfirm(false);
  };
  const handleConfirmationStop = async (confirm: boolean) => {
    setShowErrors(true);
    if (confirm) {
      handleSubmit(onSubmit)();
    }
  };

  // event change language, turn off errors message
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);

  // show errors when click button submit
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFormSubmit = () => {
    setShowErrors(true);
    handleSubmit(onSubmit)();
  };

  return (
    <div className="purchase__container">
      <PurchaseForm>
        <TGGrid container columns={12} mt={8}>
          <TGGrid item sm={12} mb={1}>
            {showErrors && codeError && (
              <Alert severity="error" icon={false}>
                {codeError}
              </Alert>
            )}
          </TGGrid>
          <TGGrid item xs={12}>
            <Typography variant="h3">{t('LicenseStart')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={1}>
            <Typography variant="body1">{t('LicenseStart.description')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={3} mb={1}>
            <Typography variant="h4">{t('ValidLicenseInformation')}</Typography>
          </TGGrid>
        </TGGrid>
        <Divider sx={{ borderBottom: '2px solid' }} />
        <TGGrid>
          <SearchResult<ColumnUpDateStopLicense>
            totalCount={rows?.count ?? 0}
            page={getValues('pageIndex')}
            columns={columns2}
            rows={rows?.list ?? []}
            handlePageChange={pageChange}
            tableCell={(row: any, rowKey: number, column2: ColumnUpDateStopLicense): ReactNode => {
              const value = row[column2.id];
              switch (column2.id) {
                case 'licenseId':
                  return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
                case 'UserNameWithLoginId':
                  if (row.userName !== null) {
                    return `${row.userName}`;
                  }

                  return '';

                case 'availableDeviceNumber':
                  return `${row.availableDeviceNumber ?? 0}`;
                case 'isStop':
                  return (
                    <Checkbox
                      checked={selectedRows.includes(row.licenseId)}
                      onChange={(event) => handleRowCheckboxChange(event, row.licenseId)}
                      color="primary"
                    />
                  );
                default:
                  return column2.format ? column2.format(value) : <OverHideText>{value}</OverHideText>;
              }
            }}
          />
        </TGGrid>
        <TGGrid container mt={10} mb={3} display="flex" justifyContent="right">
          <TGGrid item md={8} sm={11}>
            <Box display="flex" alignItems="center" justifyContent="right">
              <Button
                variant="contained"
                sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
                className="purchase-button-gray"
                onClick={handleOpenDialog}
              >
                {t('purchaseEmail.button.cancel')}
              </Button>
              <Button
                variant="contained"
                sx={{ marginLeft: '20px', width: '120px', fontSize: '14px' }}
                onClick={handleOpenDialogConfirm}
                disabled={!isChecked}
                // onClick={handleFormSubmit}
              >
                {t('updateStartBtn')}
              </Button>
            </Box>
          </TGGrid>
        </TGGrid>
        <DialogConfirmCancel open={open} onClose={handleCloseDialog} onConfirm={handleConfirmation} />
        <DialogConfirmStop
          open={openConfirm}
          onClose={handleCloseDialogConfirm}
          onSubscription={handleConfirmationStop}
        />
      </PurchaseForm>
    </div>
  );
}

export default StartLicense;

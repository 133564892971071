import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import './assets/scss/global.scss';
import themes from './themes';
import Layout from './container/layout/main';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes({ borderRadius: 6, })}>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;

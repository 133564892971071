import { Container, Typography } from '@mui/material';
import MainCard from '../maincard';

type Props = {
  children: React.ReactNode;
  title: any;
  description: React.ReactNode;
};

export default function SearchForm({ children, title, description }: Props) {
  return (
    <Container disableGutters maxWidth="xl" component="main">
      <MainCard title={title}>
        <Typography variant="h5" color="inherit">
          {description}
        </Typography>
        {children}
      </MainCard>
    </Container>
  );
}

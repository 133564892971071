import { createStore, applyMiddleware, compose } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import rootReducer from '../reducers';

// eslint-disable-next-line import/no-cycle
import rootSaga from '../sagas';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Mount it on the Store
// eslint-disable-next-line max-len
const store = createStore(rootReducer, compose(applyMiddleware(sagaMiddleware), devToolsEnhancer({})));

// Run the saga
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;

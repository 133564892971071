import { types } from '../actions/auth';

interface AuthRequest {
  type: typeof types.LOGIN_SUCCESS;
  payload?: any;
}
interface LogoutRequest {
  type: typeof types.LOGOUT;
  payload?: any;
}

type AuthActions = AuthRequest | LogoutRequest;

interface IAuthState {
  loggedIn: boolean;
  isAdmin: boolean;
  user: any;
  token: any;
  authStatus: number;
}

const initialState: IAuthState = {
  loggedIn: false,
  isAdmin: false,
  user: null,
  token: null,
  authStatus: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: AuthActions) => {
  switch (type) {
    case types.LOGIN_SUCCESS:
      state.user = payload;
      state.loggedIn = true;
      state.authStatus = state.user.authStatus;
      localStorage.setItem('token', state.user.token);
      return {
        ...state,
      };

    case types.LOGIN_ADMIN_SUCCESS:
      state.user = payload;
      state.authStatus = state.user.authStatus;
      if (payload.token) {
        state.loggedIn = true;
        state.isAdmin = true;
        localStorage.setItem('token', state.user.token);
      }

      return {
        ...state,
      };

    case types.GET_PROFILE_SUCCESS:
      state.user = payload;
      state.loggedIn = true;
      return {
        ...state,
      };

    case types.GET_TOKEN_SUCCESS:
      state.token = payload;
      state.loggedIn = true;
      return {
        ...state,
      };

    case types.GET_PROFILE_ERROR:
      state.user = null;
      state.loggedIn = false;
      localStorage.removeItem('token');
      return {
        ...state,
      };

    case types.LOGOUT:
      state.user = null;
      state.loggedIn = false;
      localStorage.removeItem('token');
      localStorage.clear();
      localStorage.setItem('locate', sessionStorage.getItem('locale') ?? 'ja');
      if (state.isAdmin) {
        state.isAdmin = false;
        if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      } else if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};

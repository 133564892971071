import { types } from '../actions/app';

interface IAppRequest {
  type: typeof types.CHANGE_LOADING;
  payload?: any;
}

interface IAppMenuRequest {
  type: typeof types.CHANGE_ACTIVE_MENU;
  payload: string;
}

type AppActions = IAppRequest | IAppMenuRequest;

interface IAppState {
  loading: boolean;
  menuActive: string;
  isEditable: boolean;
}

const initialState: IAppState = {
  loading: false,
  menuActive: '',
  isEditable: true,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: AppActions) => {
  switch (type) {
    case types.CHANGE_LOADING:
      state.loading = payload;
      return {
        ...state,
      };
    case types.CHANGE_ACTIVE_MENU:
      state.menuActive = payload;
      return {
        ...state,
      };
    case types.CHANGE_EDITABLE:
      state.isEditable = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};

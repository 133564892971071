/* eslint-disable no-alert */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { ReactNode, useEffect, useState } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Button, IconButton, Theme, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation } from 'react-router-dom';
import { getGroups } from '../../../state/actions/group';
import { start } from '../../../lib/saga-promise';
import roles from '../../../constants/roles';
import { exportCsv } from '../../../component/helpers/utility';
import SearchCriteria from '../../../component/Layout/SearchCriteria';
import SearchForm from '../../../component/Layout/SearchForm';
import SearchResult from '../../../component/Layout/SearchResult';
import CancelSelector from '../../../component/Form/CancelSelector';
import GroupSelector from '../../../component/Form/GroupSelector';
import DownloadButton from '../../../component/Form/DownloadButton';
import { Column, columns, columnsUser, ColumnUser, SearchCriteriaDefaultInput, SearchCriteriaInput } from './types';
import TGGrid from '../../../component/Elements/TGGrid';
import TGTextField from '../../../component/Elements/TGTextField';
import { cancelDevice, resetCancelDevice, searchDevice } from '../../../state/actions/device';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../../constants/app';

const localStorageKey = 'searchDeviceLicense';
interface MainProps {
  theme: Theme;
  isLastestVersion: any;
}
export default function LicenseDevice() {
  const StyledTableCell = styled('div', { shouldForwardProp: (prop) => prop !== 'isLastestVersion' })<MainProps>(({ isLastestVersion }: MainProps) => ({
    fontSize: 14,
    backgroundColor: isLastestVersion ? '' : '#e3e3415c',
    display: 'grid',
    textAlign: 'center',
  }));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);

  const { register, handleSubmit, setValue, reset, getValues, watch } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.device);
  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const UnLockedStatus = [
    {
      value: 'Unlocked',
      label: 'userInfoReadMessage.label.TerminalUnlocked',
    },
    {
      value: 'Locked',
      label: 'userInfoReadMessage.label.TerminalLocked',
    },
  ];
  const theme = useTheme();
  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
    if (query.get('licenseCode')) {
      setValue('licenseCode', String(query.get('licenseCode')));
    } else {
      setValueFromLocalStorage();
    }

    handleSubmit(searchSubmit)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    setCancelEditMode(false);
    setResetCancelEditMode(false);
    await start(searchDevice, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setValueFromLocalStorage = () => {
    const searchLocalStorage = localStorage.getItem(localStorageKey);
    if (searchLocalStorage) {
      reset(JSON.parse(searchLocalStorage) as SearchCriteriaInput);
    }
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  const handleExportCsv = (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const url = `/device/export?groupId=${encodeURIComponent(data.groupId ?? '')}&licenseCode=${encodeURIComponent(data.licenseCode)}&username=${encodeURIComponent(data.username)}&roleId=${encodeURIComponent(user.roleId)}&terminalIsUnlocked=${encodeURIComponent(data.terminalIsUnlocked)}&locale=${sessionStorage.getItem('locale') === 'cn' ? 'zh-Hans' : sessionStorage.getItem('locale')}`;
    exportCsv(url, 'DeviceLicense');
  };

  const [isCancelEditMode, setCancelEditMode] = useState<boolean>(false);
  const [isResetCancelEditMode, setResetCancelEditMode] = useState<boolean>(false);
  const [editLicenseDeviceBindId, setEditLicenseDeviceBindId] = useState<string>('');

  const editCancelBindDevice = async (licenseDeviceBindId: string) => {
    setCancelEditMode(true);
    setResetCancelEditMode(false);
    setEditLicenseDeviceBindId(licenseDeviceBindId);
  };
  const cancelCancelEditMode = () => {
    setCancelEditMode(false);
    setEditLicenseDeviceBindId('');
  };
  const doneCancelEditMode = async () => {
    await start(cancelDevice, { licenseDeviceBindId: editLicenseDeviceBindId, noLoading: true }, dispatch);
    setCancelEditMode(false);
    setEditLicenseDeviceBindId('');
    handleSubmit(searchSubmit)();
  };

  const editResetCancelBindDevice = async (licenseDeviceBindId: string) => {
    setResetCancelEditMode(true);
    setCancelEditMode(false);
    setEditLicenseDeviceBindId(licenseDeviceBindId);
  };

  const cancelResetCancelEditMode = () => {
    setResetCancelEditMode(false);
    setEditLicenseDeviceBindId('');
  };
  const doneResetCancelEditMode = async () => {
    try {
      await start(resetCancelDevice, { licenseDeviceBindId: editLicenseDeviceBindId, noLoading: true }, dispatch);
      setResetCancelEditMode(false);
      setEditLicenseDeviceBindId('');
      handleSubmit(searchSubmit)();
    } catch (error: any) {
      if (error.status === 401) {
        alert(t(error.data.message));
      } else if (error.status === 400) {
        const err = error.data.errorCode ? error.data.errorCode : error.data.replace(/[^0-9]/g, '');
        alert(t(err));
      } else {
        alert(t('common.msg.erros'));
      }
    }
  };

  return (
    <SearchForm title={t('licenseDevice.label.top')} description={t('licenseDevice.label.description')}>
      <TGGrid sx={{ mb: 2, mt: 2 }}>
        <SearchCriteria
          handleRefresh={() => {
            reset(SearchCriteriaDefaultInput);
            const searchLocalStorage = localStorage.getItem(localStorageKey);
            if (searchLocalStorage) {
              localStorage.removeItem(localStorageKey);
            }
          }}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={12} md={3} userRoleId={user.roleId} showRoles={[roles.systemAdmin]}>
            <GroupSelector
              groupId={watch('groupId')}
              handleChange={(data) => setValue('groupId', data?.groupId ?? null)}
              groups={groups}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('licenseCode')} label={t('common.label.licenseCode')} />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('username')} label={t('common.label.username')} />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <CancelSelector
              Reads={UnLockedStatus}
              handleChange={(event) => setValue('terminalIsUnlocked', event.target.value)}
              ReadStatus={watch('terminalIsUnlocked')}
              hasBlank
            />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('loginId')} label={t('common.label.userid')} />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid item xs={12} sx={{ float: 'right', mb: 2 }}>
        <DownloadButton handleClick={handleSubmit(handleExportCsv)} />
      </TGGrid>
      <TGGrid userRoleId={user.roleId} showRoles={[roles.systemAdmin]}>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.devices}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'licenseDeviceId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'groupNameWithCode':
                return `${row.groupName} < ${row.groupCode} > `;
              case 'UserNameWithLoginId':
                return `${row.userName} < ${row.loginId} > `;
              case 'versionApp':
                return <StyledTableCell theme={theme} isLastestVersion={row.isLastestVersion}>{row.versionApp}</StyledTableCell>;
              case 'cancelAction':
                if (isCancelEditMode && editLicenseDeviceBindId === row.licenseDeviceBindId) {
                  return (
                    <>
                      <IconButton color="success" onClick={doneCancelEditMode}>
                        <DoneOutlineIcon />
                      </IconButton>
                      <IconButton color="error" onClick={cancelCancelEditMode}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  );
                }
                return (
                  <Button
                    onClick={() => editCancelBindDevice(row.licenseDeviceBindId)}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={
                      row.deviceCancelDate != null
                      || (isCancelEditMode && editLicenseDeviceBindId !== row.licenseDeviceBindId)
                    }
                  >
                    {t('common.btn.release')}
                  </Button>
                );

              case 'resetCancelAction':
                if (isResetCancelEditMode && editLicenseDeviceBindId === row.licenseDeviceBindId) {
                  return (
                    <>
                      <IconButton color="success" onClick={doneResetCancelEditMode}>
                        <DoneOutlineIcon />
                      </IconButton>
                      <IconButton color="error" onClick={cancelResetCancelEditMode}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  );
                }
                return (
                  <Button
                    onClick={() => editResetCancelBindDevice(row.licenseDeviceBindId)}
                    variant="contained"
                    color="error"
                    size="small"
                    disabled={
                      row.deviceCancelDate == null
                      || (isResetCancelEditMode && editLicenseDeviceBindId !== row.licenseDeviceBindId)
                    }
                  >
                    {t('common.btn.cancel')}
                  </Button>
                );
              case 'hardDiskInfo':
                return row.hardDiskInfo ? `${row.hardDiskInfo.length > 200 ? t('common.label.information') : row.hardDiskInfo}` : '';
              default:
                return column.format ? column.format(value) : value;
            }
          }}
        />
      </TGGrid>
      <TGGrid userRoleId={user.roleId} showRoles={[roles.groupAdmin, roles.user]}>
        <SearchResult<ColumnUser>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columnsUser}
          rows={rows?.devices}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: ColumnUser): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'licenseDeviceId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'groupNameWithCode':
                return `${row.groupName} < ${row.groupCode} > `;
              case 'UserNameWithLoginId':
                return `${row.userName} < ${row.loginId} > `;
              case 'versionApp':
                return <StyledTableCell theme={theme} isLastestVersion={row.isLastestVersion}>{row.versionApp}</StyledTableCell>;
              case 'cancelAction':
                if (isCancelEditMode && editLicenseDeviceBindId === row.licenseDeviceBindId) {
                  return (
                    <>
                      <IconButton color="success" onClick={doneCancelEditMode}>
                        <DoneOutlineIcon />
                      </IconButton>
                      <IconButton color="error" onClick={cancelCancelEditMode}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  );
                }
                return (
                  <Button
                    onClick={() => editCancelBindDevice(row.licenseDeviceBindId)}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={
                      row.deviceCancelDate != null
                      || (isCancelEditMode && editLicenseDeviceBindId !== row.licenseDeviceBindId)
                    }
                  >
                    {t('common.btn.release')}
                  </Button>
                );

              case 'resetCancelAction':
                if (isResetCancelEditMode && editLicenseDeviceBindId === row.licenseDeviceBindId) {
                  return (
                    <>
                      <IconButton color="success" onClick={doneResetCancelEditMode}>
                        <DoneOutlineIcon />
                      </IconButton>
                      <IconButton color="error" onClick={cancelResetCancelEditMode}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  );
                }
                return (
                  <Button
                    onClick={() => editResetCancelBindDevice(row.licenseDeviceBindId)}
                    variant="contained"
                    color="error"
                    size="small"
                    disabled={
                      row.deviceCancelDate == null
                      || (isResetCancelEditMode && editLicenseDeviceBindId !== row.licenseDeviceBindId)
                    }
                  >
                    {t('common.btn.cancel')}
                  </Button>
                );

              default:
                return column.format ? column.format(value) : value;
            }
          }}
        />
      </TGGrid>
    </SearchForm>
  );
}

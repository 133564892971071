/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Typography, Divider, Button, List, ListItem, ListItemText, Box, Checkbox } from '@mui/material';
import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { start } from '../../../lib/saga-promise';
import { SearchLicenseRenew, SearchLicenseRenewDefault } from './types';
import PurchaseForm from '../../../component/Layout/PurchaseForm';
import TGGrid from '../../../component/Elements/TGGrid';
import routes from '../../../constants/routes';
import { inputInformationBuyer, inputInformationTrial } from '../../../state/actions/purchase';
import DialogBankTransfer from '../DialogBankTransfer';
import StepPurchase from '../stepPurchase';

function ConfirmInfo() {
  const { t, i18n } = useTranslation();
  const locate = i18n.language;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const { handleSubmit } = useForm<SearchLicenseRenew>({
    defaultValues: SearchLicenseRenewDefault,
  });
  const currentDate = new Date();
  const [dataInput, setDataInput] = useState({
    businessOwnerName: '',
    applicationDepartment: '',
    companyName: '',
    mailRegister: '',
    startDate: '',
    totalAmount: '',
    telephoneNumber: '',
    amountOfLicense: '',
  });
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [infoForm, setInfoForm] = useState('');
  const [purchaseType, setPurchaseType] = useState('');
  useEffect(() => {
    if (localStorage.getItem('purchaseType') != null) {
      const storedObjectString = localStorage.getItem('purchaseType');
      if (storedObjectString !== null) {
        setPurchaseType(storedObjectString);
      }
    }
    if (localStorage.getItem('dataInput') != null) {
      const storedObjectString = localStorage.getItem('dataInput');
      if (storedObjectString !== null) {
        const storedData = JSON.parse(storedObjectString);
        setDataInput(storedData);
        if (localStorage.getItem('purchaseType') != null) {
          setSelectedEndDate(moment(storedData?.startDate).add(29, 'day').format('YYYY/MM/DD'));
        } else {
          setSelectedEndDate(moment(storedData?.startDate).add(1, 'year').add(-1, 'day').format('YYYY/MM/DD'));
        }
      }
    }
    if (localStorage.getItem('infoForm') != null) {
      const storedObjectString = localStorage.getItem('infoForm');
      if (storedObjectString !== null) {
        setInfoForm(storedObjectString);
      }
    }
  }, []);
  const handleReturn: SubmitHandler<any> = async () => {
    if (localStorage.getItem('purchaseType') != null) {
      window.location.href = `/purchase/informationTrial/${localStorage.getItem('oneTimeKeyId')}?review=true`;
    } else {
      window.location.href = `/purchase/informationBuyer/${localStorage.getItem('oneTimeKeyId')}?review=true`;
    }
  };
  const onSubmit: SubmitHandler<any> = async () => {
    try {
      removeLocalStorage();
      const dataInputNew = {
        ...dataInput,
        Locate: locate
      };
      if (purchaseType === 'Trial') {
        const res: any = await start(inputInformationTrial, dataInputNew, dispatch);
        navigate(routes.successTrialInformation.replace(':id', res.infomationPurchaseId));
      } else {
        const res: any = await start(inputInformationBuyer, dataInputNew, dispatch);
        if (res.sessionUrl) {
          window.location.href = res.sessionUrl;
        }
      }
    } catch (error: any) {
      // eslint-disable-next-line no-empty
    }
  };

  const onSubmitBankTransfer: SubmitHandler<any> = async () => {
    try {
      removeLocalStorage();
      const dataInputNew = {
        ...dataInput,
        ModeSubscription: false,
        Locate: locate
      };
      const res: any = await start(inputInformationBuyer, dataInputNew, dispatch);
      if (res.sessionUrl) {
        window.location.href = res.sessionUrl;
      }
    } catch (error: any) {
      // eslint-disable-next-line no-empty
    }
  };

  const removeLocalStorage = () => {
    localStorage.removeItem('oneTimeKeyId');
    // localStorage.removeItem('dataInput');
    localStorage.removeItem('infoForm');
    localStorage.removeItem('purchaseType');
  };

  const [open, setOpen] = useState(false);
  // mouse event in dialog
  const handleOpenDialog = () => {
    if (purchaseType === 'Trial') {
      handleSubmit(onSubmit)();
    } else {
      setOpen(true);
    }
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleConfirmation = (confirm: boolean) => {
    setShowErrors(true);
    if (confirm) {
      handleSubmit(onSubmit)();
    } else {
      handleSubmit(onSubmitBankTransfer)();
    }
  };

  // event change language, turn off errors message
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showErrors, setShowErrors] = useState(true);
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleTextClick = () => {
    setIsChecked(!isChecked);
  };

  // show errors when click button submit
  const tabs = [
    {
      title: t('purchaseStepSendMail'),
    },
    {
      title: t('purchaseStepBuyLicense'),
    },
    {
      title: t('purchaseStepInputInformationPay'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepPurchaseComplete'),
    },
  ];

  const tabsTrial = [
    {
      title: t('purchaseStepInputMail'),
    },
    {
      title: t('purchaseStepSendMail'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepInputInformationTrail'),
    },
  ];
  return (
    <div className="purchase__container">
      <PurchaseForm>
        <StepPurchase tabs={purchaseType === 'Trial' ? tabsTrial : tabs} idx={3} />
        <TGGrid container columns={12} mt={8}>
          <TGGrid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h3">{t('purchase.title.confirmInfo')}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="body1">{purchaseType === 'Trial' ? t('purchase.notes.confirmInfo.descriptionTrial') : t('purchase.notes.confirmInfo.description')}</Typography>
          </TGGrid>
        </TGGrid>
        {infoForm !== 'UserForm' ? (
          // company form
          <TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('purchaseUserInformation2')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('purchaseCompanyName')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {dataInput.companyName}
                </Typography>
              </TGGrid>
            </TGGrid>
            {/* <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('purchaseApplicationDepartment')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {dataInput.applicationDepartment}
                </Typography>
              </TGGrid>
            </TGGrid> */}
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('purchaseBuyerInformation.success.label.corporateAdminInfo')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('purchaseBuyerInformation.success.label.fullName')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {dataInput.businessOwnerName}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('common.label.email')}
                </Typography>
              </TGGrid>
              <TGGrid item md={5} xs={8}>
                <Typography
                  variant="body1"
                  component="a"
                  href={`mailto:${dataInput.mailRegister}`}
                  className="fs-09-rem"
                >
                  {dataInput.mailRegister}
                </Typography>
              </TGGrid>
            </TGGrid>
          </TGGrid>
        ) : (
          // user form
          <TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('purchaseUserInformation3')}</Typography>
              <Divider sx={{ borderBottom: '2px solid' }} />
              <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {t('purchaseBuyerInformation.success.label.fullName')}
                  </Typography>
                </TGGrid>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {dataInput.businessOwnerName}
                  </Typography>
                </TGGrid>
              </TGGrid>
              <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {t('common.label.email')}
                  </Typography>
                </TGGrid>
                <TGGrid item md={5} xs={8}>
                  <Typography
                    variant="body1"
                    component="a"
                    href={`mailto:${dataInput.mailRegister}`}
                    className="fs-09-rem"
                  >
                    {dataInput.mailRegister}
                  </Typography>
                </TGGrid>
              </TGGrid>
              <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {t('common.label.phoneNumber')}
                  </Typography>
                </TGGrid>
                <TGGrid item md={3} xs={4}>
                  <Typography variant="body1" className="fs-09-rem">
                    {dataInput.telephoneNumber}
                  </Typography>
                </TGGrid>
              </TGGrid>
            </TGGrid>
          </TGGrid>
        )}
        <TGGrid item xs={12} mt={3} mb={1}>
          <Typography variant="h4">{t('registerContent')}</Typography>
        </TGGrid>
        <Divider sx={{ borderBottom: '2px solid' }} />
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
          <TGGrid item md={3} xs={4}>
            <Typography variant="body1" className="fs-09-rem">
              {t('registerDate')}
            </Typography>
          </TGGrid>
          <TGGrid item md={3} xs={4}>
            <Typography variant="body1" className="fs-09-rem">
              {moment(currentDate).format('YYYY/MM/DD')}
            </Typography>
          </TGGrid>
        </TGGrid>
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
          <TGGrid item md={3} xs={4}>
            {purchaseType !== 'Trial' ? (
              <Typography variant="body1" className="fs-09-rem">
                {t('purchaseBuyerInformation.success.label.startDate')}
              </Typography>
            ) : (
              <Typography variant="body1" className="fs-09-rem">
                {t('trialStartDate')}
              </Typography>
            )}
          </TGGrid>
          <TGGrid item md={3} xs={4}>
            <Typography variant="body1" className="fs-09-rem">
              {moment(dataInput.startDate).format('YYYY/MM/DD')}
            </Typography>
          </TGGrid>
        </TGGrid>
        {purchaseType === 'Trial' && (
          <>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('eventTrial')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('30days')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('eventEndTrial')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {selectedEndDate}
                </Typography>
              </TGGrid>
            </TGGrid>
          </>
        )}
        {purchaseType !== 'Trial' && (
          <>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('common.label.endDate')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8} pt={2}>
                <Typography variant="body1" className="fs-09-rem">
                  {selectedEndDate}
                </Typography>
                <List>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('confirmEnddateEx1')} />
                  </ListItem>
                </List>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('deliveryTime')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={1}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('deliveryTimeEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('additionForSalesPrice')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('additionForSalesPriceEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('licensesBuy')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {dataInput.amountOfLicense}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('LicenseUnitPrice')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('8,800')}
                  &nbsp;
                  {t('YenTaxIncluded')}
                </Typography>
                <List>
                  <ListItem sx={{ padding: '0' }}>
                    <ListItemText primary={t('LicenseUnitPriceEx')} />
                  </ListItem>
                </List>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('TotalAmount')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {dataInput.totalAmount}
                  {' '}
&nbsp;
                  {t('YenTaxIncluded')}
                </Typography>
              </TGGrid>
            </TGGrid>
            {/* 修正スタート箇所 */}
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('PaymentMethods')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardCompany')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardCompanyEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            {/* <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('WalletCompany')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('WalletCompanyEx')}
                </Typography>
              </TGGrid>
            </TGGrid> */}
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankTransferDetails')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankTransferDetailsEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            {/* 修正終了箇所 */}
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('PaymentDeadline')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardPayment')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CreditCardPaymentEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            {/* <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('WalletPayment')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('WalletPaymentEx')}
                </Typography>
              </TGGrid>
            </TGGrid> */}
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankPayment')}
                </Typography>
              </TGGrid>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('BankPaymentEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('RegardingCancellationTermination')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ProductExchangeReturn')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ProductExchangeReturnEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('Cancellation')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('CancellationEx')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('RegardingContractExtension')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
            <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
              <TGGrid item md={3} xs={4}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ContractExtension')}
                </Typography>
              </TGGrid>
              <TGGrid item md={9} xs={8}>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ContractExtensionEx1')}
                </Typography>
                <Typography variant="body1" className="fs-09-rem">
                  {t('ContractExtensionEx2')}
                </Typography>
              </TGGrid>
            </TGGrid>
            <TGGrid item xs={12} mt={3} mb={1}>
              <Typography variant="h4">{t('userImport.label.csvPName')}</Typography>
            </TGGrid>
            <Divider sx={{ borderBottom: '2px solid' }} />
          </>
        )}
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
          <TGGrid item md={3} xs={4}>
            {purchaseType !== 'Trial' && (
              <Typography variant="body1" className="fs-09-rem">
                {t('userImport.label.csvPName')}
              </Typography>
            )}
          </TGGrid>
          <TGGrid item md={9} xs={8}>
            <Typography mt={4} display="flex" alignItems="center" onClick={handleTextClick}>
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              {t('noteContractEx')}
            </Typography>
          </TGGrid>
        </TGGrid>
        <TGGrid container mt={5} mb={3} display="flex" justifyContent="right">
          <TGGrid item md={8} xs={11}>
            <Box display="flex" alignItems="center" justifyContent="right">
              <Button
                variant="contained"
                sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
                className="purchase-button-gray"
                onClick={handleReturn}
              >
                {t('returnButton')}
              </Button>
              <Button
                variant="contained"
                disabled={!isChecked}
                sx={{ marginLeft: '20px', width: '120px', fontSize: '14px' }}
                // onClick={handleFormSubmit}
                onClick={handleOpenDialog}
              >
                {t('purchase.button.next')}
              </Button>
            </Box>
          </TGGrid>
        </TGGrid>
      </PurchaseForm>
      <DialogBankTransfer open={open} onClose={handleCloseDialog} onSubscription={handleConfirmation} />
    </div>
  );
}

export default ConfirmInfo;

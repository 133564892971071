import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  LoginId: string;
  GroupCode: string;
  MailRegister: string;
  UserName: string;
};

export type Column = TableColumn & {
  id:
    | 'infomationPurchaseId'
    | 'quantity'
    | 'totalAmount'
    | 'startTimeLicense'
    | 'userName'
    | 'mailRegister'
    | 'loginId'
    | 'groupCode'
    | 'mailLicense'
    | 'countSendMailPayment';
};

export const columns: Column[] = [
  {
    id: 'infomationPurchaseId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'loginId',
    label: 'common.label.userid',
    align: 'center',
  },
  {
    id: 'groupCode',
    label: 'common.label.groupid',
    align: 'center',
  },
  {
    id: 'userName',
    label: 'common.label.username',
    align: 'center',
  },
  {
    id: 'mailRegister',
    label: 'common.label.email',
    align: 'center',
  },
  {
    id: 'startTimeLicense',
    label: 'common.label.startDate',
    align: 'center',
  },
  {
    id: 'quantity',
    label: 'common.label.quantity',
    align: 'center',
  },
  {
    id: 'totalAmount',
    label: 'TotalAmount',
    align: 'center',
  },
  {
    id: 'countSendMailPayment',
    label: 'CountSendMailPayment',
    align: 'center',
  },
  {
    id: 'mailLicense',
    label: 'common.label.checkbox',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  LoginId: string;
  GroupCode: string;
  MailRegister: string;
  UserName: string;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  LoginId: '',
  GroupCode: '',
  MailRegister: '',
  UserName: '',
  pageIndex: DEFAULT_PAGE,
};

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';
import TGGrid from '../../component/Elements/TGGrid';
import PurchaseForm from '../../component/Layout/PurchaseForm';
import routes from '../../constants/routes';
import { start } from '../../lib/saga-promise';
import { changePaymentMethod, getChangePaymentMethodFlag } from '../../state/actions/license';

function ChangePaymentMethod() {
  const user = useSelector((state: RootStateOrAny) => state.auth.user);

  const [currentPaymentMethodFlag, setCurrentPaymentMethodFlag] = useState(user ? user.currentPaymentMethodIsBankFlag : true);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(currentPaymentMethodFlag ? 'Bank' : 'CreditCard');
  const [newPaymentMethod, setNewPaymentMethod] = useState(currentPaymentMethodFlag ? 'CreditCard' : 'Bank');
  const dispatch = useDispatch();

  const getProfileUser = async () => {
    try {
      const res: any = await start(getChangePaymentMethodFlag, null, dispatch);
      setCurrentPaymentMethodFlag(res.currentPaymentMethodIsBankFlag);
      setCurrentPaymentMethod(res.currentPaymentMethodIsBankFlag ? 'Bank' : 'CreditCard');
      setNewPaymentMethod(res.currentPaymentMethodIsBankFlag ? 'CreditCard' : 'Bank');
    // eslint-disable-next-line no-empty
    } catch (error: any) {
    }
  };

  useEffect(() => {
    getProfileUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (user && !user.changePaymentMethodFlag) {
    window.location.href = routes.pageNotFound;
  }

  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = routes.licenseUser;
  };

  const handleChangePaymentMethod: SubmitHandler<any> = async () => {
    try {
      localStorage.setItem('currentPaymentMethod', newPaymentMethod);
      const datasInput = {
        userId: user.userId,
      };
      const res: any = await start(changePaymentMethod, datasInput, dispatch);
      if (res.sessionUrl) {
        window.location.href = res.sessionUrl;
      }
    // eslint-disable-next-line no-empty
    } catch (error: any) {
    }
  };

  return (
    <div className="purchase__container">
      <PurchaseForm>
        <TGGrid container columns={12} mt={8} ml={4}>
          <TGGrid item xs={12} style={{ margin: 'auto', textAlign: 'center' }}>
            <img src={`/${currentPaymentMethod}-icon.svg`} alt="" style={{ width: '160px' }} />
            <Typography mt={4} variant="h3">{t(`currentPaymentMethodIs${currentPaymentMethod}`)}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={4}>
            <Typography variant="body1">{t(`content1CurrentPaymentMethodIs${currentPaymentMethod}`)}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={2}>
            <Typography variant="body1">{t(`content2CurrentPaymentMethodIs${currentPaymentMethod}`)}</Typography>
          </TGGrid>
          <TGGrid item xs={12} mt={2}>
            <Typography variant="body1">{t(`content3CurrentPaymentMethodIs${currentPaymentMethod}`)}</Typography>
          </TGGrid>
        </TGGrid>
        <TGGrid
          container
          columns={12}
          mt={4}
          ml={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TGGrid
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={`/${currentPaymentMethod}-icon.svg`}
              alt="Bank Icon"
              style={{ width: '120px' }}
            />
          </TGGrid>
          <TGGrid ml={6} mr={6}>
            <img
              src="/arrow-right.svg"
              alt="Credit Card Icon"
              style={{
                width: '120px',
              }}
            />
          </TGGrid>
          <TGGrid
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={`/${newPaymentMethod}-icon.svg`}
              alt="Credit Card Icon"
              style={{
                width: '120px'
              }}
            />
          </TGGrid>
        </TGGrid>
        <TGGrid container mt={5} mb={3} display="flex" justifyContent="right">
          <TGGrid item md={8} xs={11}>
            <Box display="flex" alignItems="center" justifyContent="right">

              <Button
                variant="contained"
                sx={{ marginLeft: '10%', width: 'auto', fontSize: '14px' }}
                onClick={handleClickOpen}
              >
                {t('changePaymentMethodButton')}
              </Button>
              <Button
                variant="contained"
                sx={{ marginLeft: '20px', width: '120px', fontSize: '14px' }}
                className="purchase-button-gray"
                onClick={handleClick}
              >
                {t('common.btn.cancel')}
              </Button>
            </Box>
          </TGGrid>
        </TGGrid>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <DialogContentText>{t('changePaymentMethodConfirm')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" autoFocus>{t('btnNo')}</Button>
            <Button onClick={handleChangePaymentMethod} variant="contained" autoFocus>{t('btnYes')}</Button>
          </DialogActions>
        </Dialog>

      </PurchaseForm>
    </div>
  );
}

export default ChangePaymentMethod;

export const types = {
  CREATE_GROUP_PASSWORD_POLICY: 'CREATE_GROUP_PASSWORD_POLICY',
  CREATE_GROUP_PASSWORD_POLICY_SUCCESS: 'CREATE_GROUP_PASSWORD_POLICY_SUCCESS',
  EDIT_GROUP_PASSWORD_POLICY: 'EDIT_GROUP_PASSWORD_POLICY',
  EDIT_GROUP_PASSWORD_POLICY_SUCCESS: 'EDIT_GROUP_PASSWORD_POLICY_SUCCESS',
  GROUP_PASSWORD_POLICY_DETAIL: 'GROUP_PASSWORD_POLICY_DETAIL',
  GROUP_PASSWORD_POLICY_DETAIL_SUCCESS: 'GROUP_PASSWORD_POLICY_DETAIL_SUCCESS',
};

export const createGroupPasswordPolicy = (payload: any) => ({
  type: types.CREATE_GROUP_PASSWORD_POLICY,
  payload
});

export const createGroupPasswordPolicySuccess = (payload: any) => ({
  type: types.CREATE_GROUP_PASSWORD_POLICY_SUCCESS,
  payload
});

export const editGroupPasswordPolicy = (payload: any) => ({
  type: types.EDIT_GROUP_PASSWORD_POLICY,
  payload
});

export const editGroupPasswordPolicySuccess = (payload: any) => ({
  type: types.EDIT_GROUP_PASSWORD_POLICY_SUCCESS,
  payload
});

export const groupPasswordPolicyDetail = (payload: any) => ({
  type: types.GROUP_PASSWORD_POLICY_DETAIL,
  payload
});

export const groupPasswordPolicyDetailSuccess = (payload: any) => ({
  type: types.GROUP_PASSWORD_POLICY_DETAIL_SUCCESS,
  payload
});

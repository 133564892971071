import * as yup from 'yup';
import { t } from 'i18next';

export type RegisterInput = {
  groupNameWithCode: string;
  isRequiredLowerAlphabetChars: boolean;
  isRequiredUpperAlphabetChars: boolean;
  isRequiredNumericChars: boolean;
  isRequiredMarkChars: boolean;
  minimumLength: number;
  expireDays: number;
  changeHistoryLimit: number;
  autoLockoutCount: number;
  autoUnsetLockOutMinutes: number;
};

export const schemaEdit = yup.object({
  minimumLength: yup
    .number()
    .typeError(t('licenseCreate.msg.minimumLengthNumber'))
    .required(t('licenseCreate.msg.minimumLengthNumber'))
    .max(127, t('licenseCreate.msg.minimumLengthNumber'))
    .min(1, t('licenseCreate.msg.minimumLengthNumber')),
  expireDays: yup
    .number()
    .typeError(t('licenseCreate.msg.expireDays'))
    .required(t('licenseCreate.msg.expireDays'))
    .max(10000, t('licenseCreate.msg.expireDays'))
    .min(0, t('licenseCreate.msg.expireDays')),
  changeHistoryLimit: yup
    .number()
    .typeError(t('licenseCreate.msg.changeHistoryLimit'))
    .required(t('licenseCreate.msg.changeHistoryLimit'))
    .max(10, t('licenseCreate.msg.changeHistoryLimit'))
    .min(0, t('licenseCreate.msg.changeHistoryLimit')),
  autoLockoutCount: yup
    .number()
    .typeError(t('licenseCreate.msg.autoLockoutCount'))
    .required(t('licenseCreate.msg.autoLockoutCount'))
    .max(100, t('licenseCreate.msg.autoLockoutCount'))
    .min(0, t('licenseCreate.msg.autoLockoutCount')),
  autoUnsetLockOutMinutes: yup
    .number()
    .typeError(t('licenseCreate.msg.autoUnsetLockOutMinutes'))
    .required(t('licenseCreate.msg.autoUnsetLockOutMinutes'))
    .max(999, t('licenseCreate.msg.autoUnsetLockOutMinutes'))
    .min(0, t('licenseCreate.msg.autoUnsetLockOutMinutes')),
});

export const schemaNew = schemaEdit;

export type TableColumn = {
  id: string;
  label: string;
  minWidth?: number;
  width?: number;
  align?: 'right' | 'left' | 'center' | 'inherit' | 'justify' | undefined;
  format?: (value: any) => string;
};

export type ColumnCsvFormat = TableColumn & {
  id: 'no' | 'name' | 'required' | 'description';
};

export const columnCsvFormat: ColumnCsvFormat[] = [
  {
    id: 'no',
    label: 'csvFormat.label.no',
    align: 'center',
    width: 50,
  },
  {
    id: 'name',
    label: 'csvFormat.label.name',
    width: 200,
  },
  {
    id: 'required',
    label: 'csvFormat.label.required',
    align: 'center',
    width: 50,
  },
  {
    id: 'description',
    label: 'csvFormat.label.description',
  },
];

export type CsvFormat = {
  no: string;
  name: string;
  required: boolean;
  description: string;
};

export type ColumnError = TableColumn & {
  id: 'no' | 'col' | 'errorMsg';
};

export const columnsError: ColumnError[] = [
  {
    id: 'no',
    label: '行',
    align: 'center',
  },
  {
    id: 'col',
    label: '列',
    align: 'center',
  },
  {
    id: 'errorMsg',
    label: 'common.label.errorMsg',
  },
];

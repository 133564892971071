import { Button } from '@mui/material';
import { t } from 'i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

type Props = {
  handleClick: () => void;
};

export default function RegistNewButton({ handleClick }: Props) {
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      sx={{ ml: 1 }}
      startIcon={<AddCircleOutlineIcon />}
    >
      {t('common.btn.registNew')}
    </Button>
  );
}

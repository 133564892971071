import { call, put, takeLatest } from 'redux-saga/effects';
import DeviceServices from '../../services/device';
import { types, searchDeviceSuccess, deviceDetailSuccess, editDeviceSuccess, createDeviceSuccess, cancelDeviceSuccess, resetCancelDeviceSuccess } from '../actions/device';
import { sagaPromise } from '../../lib/saga-promise';
import { changeLoading } from '../actions/app';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}

interface Iaction {
  type: string,
  payload: any,
}

function* createDevice(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(DeviceServices.createDevice, action.payload);
    yield put(createDeviceSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* editDevice(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(DeviceServices.editDevice, action.payload);
    yield put(editDeviceSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* cancelDevice(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(DeviceServices.cancelDevice, action.payload);
    yield put(cancelDeviceSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* resetCancelDevice(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(DeviceServices.resetCancelDevice, action.payload);
    yield put(resetCancelDeviceSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* searchDevice(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(DeviceServices.searchDevice, action.payload);
    yield put(searchDeviceSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* deviceDetail(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      // yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(DeviceServices.deviceDetail, action.payload);
    yield put(deviceDetailSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}



export default function* deviceSaga() {
  yield takeLatest(types.CREATE_DEVICE, sagaPromise(createDevice));
  yield takeLatest(types.EDIT_DEVICE, sagaPromise(editDevice));
  yield takeLatest(types.CANCEL_DEVICE, sagaPromise(cancelDevice));
  yield takeLatest(types.RESET_CANCEL_DEVICE, sagaPromise(resetCancelDevice));
  yield takeLatest(types.SEARCH_DEVICE, sagaPromise(searchDevice));
  yield takeLatest(types.DEVICE_DETAIL, sagaPromise(deviceDetail));
}

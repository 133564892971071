/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import { TextField, MenuItem } from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

type Props = {
  roles: Array<{ value: number; label: string }>;
  name?: string;
  handleChange: (data: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  roleId: string | undefined;
  isError?: boolean;
  errorMessage?: React.ReactNode;
  hasBlank?: boolean;
};

export default function RoleSelector({
  roles,
  name,
  handleChange,
  roleId,
  isError,
  errorMessage,
  hasBlank = false,
}: Props) {
  const isEditable = useSelector((state: RootStateOrAny) => state.app.isEditable);
  return (
    <TextField
      select
      fullWidth
      size="small"
      onChange={handleChange}
      name={name}
      value={roleId}
      error={isError}
      helperText={t(errorMessage as string)}
      label={t('common.label.role')}
      variant={!isEditable ? 'standard' : 'outlined'}
      InputProps={{
        readOnly: !isEditable,
      }}
    >
      {hasBlank && (
        <MenuItem key="0" value="0">
          <br />
        </MenuItem>
      )}
      {roles.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {t(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
}

import { Button, Container, Typography } from '@mui/material';
import { t } from 'i18next';
import MainCard from '../maincard';

type Props = {
  children: React.ReactNode;
  title: any;
  description: React.ReactNode;
  handleCancel: () => void;
};

export default function ImportForm({ children, title, description, handleCancel }: Props) {
  return (
    <Container disableGutters maxWidth="xl" component="main">
      <MainCard
        title={title}
        secondary={(
          <Button onClick={handleCancel} variant="contained" sx={{ my: 1, mx: 1 }} color="primary">
            {t('common.btn.cancel')}
          </Button>
        )}
      >
        <Typography variant="h5" color="inherit">
          {description}
        </Typography>
        {children}
      </MainCard>
    </Container>
  );
}

import axios from 'axios';
import { BASE_URL } from '../../constants/app';

export const exportCsv = (urlApi: string, downloadFileName: string) => {
  axios({
    url: `${BASE_URL}${urlApi}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    const d: any = new Date();
    const date = String(
      `${d.getFullYear()}${`0${d.getMonth() + 1}`.slice(-2)}${`0${d.getDate()}`.slice(-2)}_${`0${d.getHours()}`.slice(
        -2
      )}${`0${d.getMinutes()}`.slice(-2)}${`0${d.getSeconds()}`.slice(-2)}`
    );
    link.setAttribute('download', `${downloadFileName}_${date}.csv`);
    document.body.appendChild(link);
    link.click();
  });
};

export const getRegExpSchemaPasswordPolicy = (
  _IsRequiredLowerAlphabetChars: boolean,
  _IsRequiredUpperAlphabetChars: boolean,
  _IsRequiredNumericChars: boolean,
  _IsRequiredMarkChars: boolean
) => {
  let strReg = '';
  const REGEX_LOWER_ALPHABET = '(?=.*[a-z])';
  const REGEX_UPPER_ALPHABET = '(?=.*[A-Z])';
  const REGEX_NUMERIC = '(?=.*[0-9])';
  const REGEX_MARK = '(?=.*[@$!%*#?&])';
  if (_IsRequiredLowerAlphabetChars) {
    strReg = strReg.concat(REGEX_LOWER_ALPHABET);
  }
  if (_IsRequiredUpperAlphabetChars) {
    strReg = strReg.concat(REGEX_UPPER_ALPHABET);
  }
  if (_IsRequiredNumericChars) {
    strReg = strReg.concat(REGEX_NUMERIC);
  }
  if (_IsRequiredMarkChars) {
    strReg = strReg.concat(REGEX_MARK);
  }
  return new RegExp(`^${strReg}[A-Za-z\\d@$!%*#?&].*$`);
};

// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class UserServices {
  static createUser(params: any) {
    const url = 'user/create';
    return axiosClient.post(url, params);
  }

  static searchMUser(params: any) {
    const url = 'user/msearch';
    return axiosClient.get(url, { params });
  }

  static searchUser(params: any) {
    const url = 'user/search';
    return axiosClient.get(url, { params });
  }

  static userDetail(params: any) {
    const url = 'user/detail';
    return axiosClient.get(url, { params });
  }

  static canLicenseBindUsers(params: any) {
    const url = 'user/can_license_bind';
    return axiosClient.get(url, { params });
  }

  static editUser(params: any) {
    const url = 'user/edit';
    return axiosClient.put(url, params);
  }

  static passwordReset(params: any) {
    const url = 'user/password_reset';
    return axiosClient.put(url, params);
  }

  static passwordChange(params: any) {
    const url = 'user/password_change';
    return axiosClient.put(url, params);
  }

  static updateUserLicense(params: any) {
    const url = 'user/update_user_license';
    return axiosClient.put(url, params);
  }

  static passwordExpiredChange(params: any) {
    const url = 'user/password_expired_change';
    return axiosClient.put(url, params);
  }
}
export default UserServices;

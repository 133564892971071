export const types = {
  CREATE_GROUP: 'CREATE_GROUP',
  CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
  EDIT_GROUP: 'EDIT_GROUP',
  EDIT_GROUP_SUCCESS: 'EDIT_GROUP_SUCCESS',
  SEARCH_GROUPS: 'SEARCH_GROUPS',
  SEARCH_GROUPS_SUCCESS: 'SEARCH_GROUPS_SUCCESS',
  GROUP_DETAIL: 'GROUP_DETAIL',
  GROUP_DETAIL_SUCCESS: 'GROUP_DETAIL_SUCCESS',
  GET_GROUPS: 'GET_GROUPS',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
};

export const createGroup = (payload: any) => ({
  type: types.CREATE_GROUP,
  payload
});

export const createGroupSuccess = (payload: any) => ({
  type: types.CREATE_GROUP_SUCCESS,
  payload
});

export const editGroup = (payload: any) => ({
  type: types.EDIT_GROUP,
  payload
});

export const editGroupSuccess = (payload: any) => ({
  type: types.EDIT_GROUP_SUCCESS,
  payload
});

export const getGroups = (payload: any) => ({
  type: types.GET_GROUPS,
  payload
});

export const getGroupSuccess = (payload: any) => ({
  type: types.GET_GROUPS_SUCCESS,
  payload
});

export const searchGroups = (payload: any) => ({
  type: types.SEARCH_GROUPS,
  payload
});

export const searchGroupsSuccess = (payload: any) => ({
  type: types.SEARCH_GROUPS_SUCCESS,
  payload
});

export const groupDetail = (payload: any) => ({
  type: types.GROUP_DETAIL,
  payload
});

export const groupDetailSuccess = (payload: any) => ({
  type: types.GROUP_DETAIL_SUCCESS,
  payload
});

/* eslint-disable react/require-default-props */
import { Checkbox, FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  registration: Partial<UseFormRegisterReturn>;
  checked?: boolean;
};

export default function IsInvalidCheckbox({ checked, registration }: Props) {
  return (
    <FormControlLabel
      control={<Checkbox {...registration} checked={checked} />}
      label={t('common.label.includeInvalid') as string}
      labelPlacement="start"
    />
  );
}
